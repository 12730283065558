@media screen and (min-width: 1200px) {
  body{
    background-position: right 7.6% top 54px;
  }
  .masonry{
    width: 75%!important;
    float: right;
    > div {
      width: 33.33%;
    }
  }
  .container{
    &-main{
      //display: block;
      flex-direction: row;
      padding: 0;
      //justify-content: stretch;
      //overflow: hidden;
    }
    &.cols-three{
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      //padding: 8px 0 0;
      aside.left{
        //padding-top: 8px;
      }
      section.center{
        width: 75%;
        display: flex;
        flex-direction: column;
        .masonry{
          width: 100%!important;
        }
      }
      aside.right{
        width: 75%;
        //padding-top: 8px;
      }
    }
  }
  aside.left{
    &.horizontal{
      .search-wrap {
        width: 75%;
        &.disable{
          width: 266px
        }
      }
      .filter-wrap {
        width: 25%;
      }
      #opts{
        padding: 12px 0;
      }
    }
    &.vertical {
      width: 25%;
      float: left;
      flex-direction: column;
      .search-wrap {
        width: 100%;
        order: 1;
        &.disable {
          width: 100%;
        }
      }
      .filter-wrap {
        flex: 0 1;
        width: 100%;
        order: 2;
      }
      .why-wrap {
        order: 3;
      }
      #search {
        //margin: 0 8px;
      }
      #filter{
        h4{
          font-family: "Noto Serif Medium";
          font-weight: 500;
          font-size: 20px;
          line-height: 35px;
          text-transform: capitalize;
          color: $color-primary!important;
          padding: 16px 16px 0;
          cursor: auto;
          &:before{
            display: none;
          }
          i{
            display: none;
          }
        }
      }
      #opts{
        display: block!important;
        padding: 0 0 16px;
        border: none;
        >div{
          flex-wrap: wrap;
        }
        .sections{
          //padding: 14px 12px 16px;
        }
        .pravs{
          .select-wrap{
            width: 100%;
          }
          select{
            width: 100%;
          }
        }
        .years {
          //padding: 1px 16px 8px;
          >div:nth-child(2){
            width: 100%;
          }
        }
        .clear{
          top: 18px;
          left: 91px;
          right: auto;
          bottom: auto;
        }
      }
      #filter.filter-articles{
        #opts{
          > div{
            padding-right: 12px;
          }
          .clear{
            right: 0;
            left: auto;
          }
        }
      }
    }
    #opts{
      border-color: $color-border;
      > div{
        padding: 12px 16px;
        border: none!important;
      }
      .sections {
        padding: 12px 12px 4px;
      }
      .pravs{
        padding: 12px 8px 0;
        margin-bottom: -4px;
      }
      .years {
        padding: 6px 16px;
      }
    }
  }
  #search {
    background: none;
    @include border-important;
  }
  #filter{
    background: none !important;
    @include border-important;
  }

  /*Почему мы?*/
  .why-wrap{
    display: block;
    width: 100%;
    float: left;
  }
  .why{
    padding: 16px 16px 20px;
    margin: 0 8px;
    h4{
      font-family: "Noto Serif Medium";
      font-weight: 500;
      font-size: 20px;
      line-height: 35px;
    }
    li{
      padding: 2px 0;
    }
  }

  .button-send {
    position: static;
    width: 250px;
    font-size: 18px;
    line-height: 50px;
    margin-right: 8px;
    z-index: auto;
    &:hover{
      transform: translateY(-2px);
      box-shadow: 0 3px 16px rgba(174,109,33,.25),0 1px 4px rgba(174,109,33,.25);
    }
  }
  header{
    .desc{
      margin-right: 150px;
    }
  }
  nav{
    ul.menu{
      margin: 0 auto;
      li.more{
        display: block;
      }
      a{
        padding: 5px 16px;
      }
    }
    .fav{
      position: relative;
      span{
        position: absolute;
        top: -1px;
        right: 0;
        left: auto;
      }
    }
    a#more-nav{
      display: none;
    }
  }
  #wrap-drop {
    display: none!important;
  }
  .breadcrumbs{
    //margin: 7px 19px 6px;
    margin: 7px 19px 14px;
  }
  /* Карточка монеты и т.п. */
  section.left{
    //display: flex;
    //flex-wrap: wrap;
    //flex-direction: column;
    .breadcrumbs{
      //width: 100%;
    }
    .sidebar{
      float: right;
      width: 25%;
    }
  }
  .product{
    float: left;
    width: 75%;
  }
  .share-wrap {
    padding: 0 8px 16px;
  }
  .share{
    @include border;
  }
  .related{
    width: 75%;
    .masonry {
      width: 100%!important;
    }
  }
  .similar-wrap{
    width: 75%;
    .masonry {
      width: 100%!important;
    }
  }
  .viewed-wrap{
    width: 75%;
    .masonry {
      width: 100%!important;
    }
  }
  article{
    margin: 0 8px 16px;
  }

}
@media screen and (min-width: 1200px) and (max-width: 1339px) {
  .container.cols-three{
    aside.right{
      .form-wrap{
        @include form-wrap
      }
      .pluses-wrap{
        @include pluses-wrap
      }
    }
  }
}