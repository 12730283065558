.slider-container {
    width: 256px;
}
.slider-container .back-bar {
    position: relative;
    height: 4px;
    border-radius: 2px;
    background: $color-hover-back;
}
.slider-container .back-bar .selected-bar {
    position: absolute;
    height: 100%;
    background: $color-active-filter;
}
.slider-container .back-bar .pointer {
    position: absolute;
    width: 18px;
    height: 18px;
    top: -7px;
    border-radius: 9px;
    background: $color-active-filter;
    cursor: pointer;
    z-index: 2;
}
.slider-container .back-bar .pointer-label {
    display: none;
}
.slider-container .back-bar .pointer.over {
    background: $color-active;
    box-shadow: 0 1px 8px rgba(76, 23, 0, 0.2);
}
.slider-container .back-bar .pointer.last-active {
    z-index: 3;
}
.slider-container .back-bar .focused {
    z-index: 10;
}
.slider-container .clickable-dummy {
    cursor: pointer;
    position: absolute;
    top: -2px;
    width: 100%;
    height: 200%;
    z-index: 1;
}
.slider-container.slider-readonly .clickable-dummy,
.slider-container.slider-readonly .pointer {
    cursor: auto;
}

