@media screen and (min-width: 1340px) {
  body{
    background-position: right 65px top 54px;
  }
  .masonry.cols-3{
    > div {
      width: 33.33%;
    }
  }
  .masonry.cols-4{
    width: 80% !important;
    > div {
      width: 25%;
    }
  }
  aside.left{
    &.horizontal{
      .search-wrap {
        width: 80%;
      }
      .filter-wrap {
        width: 20%;
      }
    }
    &.vertical{
      width: 20%;
    }
  }
  aside.right{
    > div{
      //margin: 0 8px 16px;
    }
  }
  section.left{
    .sidebar {
      width: 20%;
    }
  }
  .container{
    width: 1330px;
    margin: 0 auto;
    /*&-main{
      display: block;
      //flex-direction: row;
      padding: 17px 0 0;
      overflow: hidden;
    }*/
    &.cols-three{
      flex-wrap: nowrap;
      section.center{
        width: 60%;
      }
      aside.right{
        width: 20%;
        //padding-top: 8px;
      }
      &.list-coins{
        h1{
          margin-right: -33.34%;
        }
        .breadcrumbs{
          margin-right: -33.34%;
        }
        .control{
          margin-right: -33.34%;
          &.bottom{
            margin-right: auto;
          }
        }
        aside.right{
          //margin-top: 133px;
          margin-top: 125px;
        }
      }
      &.list-articles{
        h1{
          margin-right: -33.34%;
        }
        .control{
          margin-right: -33.34%;
          &.bottom{
            margin-right: auto;
          }
        }
        aside.right{
          margin-top: 61px;
        }
      }
    }
  }

  header{
    .container{
      //padding: 26px 0 13px;
      height: 119px;
      padding: 26px 0 28px;
    }
    .desc{
      display: flex;
      align-items: center;
      width: auto;
      margin-right: 140px;
      border: none;
      h4{
        width: 130px;
        font-size: 16px;
        padding: 0 11px;
        text-align: right;
      }
      p{
        width: 227px;
        padding: 0 8px 0 11px;
        border-left: 1px solid #996036;
      }
    }
  }
  nav{
    ul.menu{
      width: 1318px;
      padding: 0;
    }
  }
  .container{
    &.text {
      padding-left: 271px;
    }
  }
  .product {
    width: 80%;
    > div {
      float: left;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        top: 0;
        bottom: 0;
        left: 40%;
        background: $color-hover-back;
      }
    }
    h1{
      width: 60%;
    }
    .photos-wrap{
      width: 40%;
      .photos{
        margin-right: 14px;
      }
    }
    #thumbs{
      padding: 16px 24px;
    }
    .desc{
      width: 60%;
    }
  }
  .related {
    width: 80%;
    .masonry.cols-4{
      width: 100%!important;
    }
  }
  .similar-wrap {
    width: 80%;
    .masonry.cols-4{
      width: 100%!important;
    }
  }
  .viewed-wrap {
    width: 80%;
    .masonry.cols-4{
      width: 100%!important;
    }
  }
  footer{
    .container{
      padding: 40px 271px 45px;
      >div{
        width: 66.67%;
      }
    }
    ul{
      width: 33.33%;
    }
  }
}
@media screen and (min-width: 1410px) {
  body{
    background-position: right 11.3% top 54px;
  }
}
@media screen and (min-width: 1506px) {
  body{
    background-position: right 14.6% top 54px;
  }
}
@media screen and (min-width: 1570px) {
  body{
    background-position: right 16.6% top 54px;
  }
}
@media screen and (min-width: 1890px) {
  body{
    background-position: right 23.7% top 54px;
  }
}