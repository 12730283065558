@import 'vars';
@import 'jquery.range';

html{
  height: 100%;
  overflow-x: hidden;
}
body {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  font-family: $family-stack;
  color: $color-primary;
  //background: #F8F6E6;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 22px;
  //background-color: #F8F6E6;
  background: url("/catalog/view/theme/custom/img/head-back.png") no-repeat -83px 59px, url("/catalog/view/theme/custom/img/back.png"), #F8F6E8;
  //background-position: -83px 70px;
  //background-size: 430px 138px, 400px 400px;
  &.fixed{
    overflow-y: hidden;
  }
}

h1{
  padding: 1px 65px 20px 22px;
  span{
    font-family: inherit;
    white-space: nowrap;
  }
  img{
    @extend %head-icon;
    top: -1px;
  }
}
section.center h2{
  img{
    @extend %head-icon;
    top: 4px;
  }
}
section.center{
  > h2{
    //font-family: $family-head;
    //font-size: 24px;
    //line-height: 27px;
    //font-weight: 500;
    overflow: hidden;
    padding: 8px 60px 16px 22px;
  }
  > h1 + h2{
    //padding: 0 22px 16px;
    margin-top: -16px;
  }
}
.container{
  display: flex;
  flex-direction: column;
  margin: 0 8px;
  &.text{
    display: block;
    padding: 8px 8px;
    p{
      font-size: 14px;
      margin: 0 16px 16px;
    }
  }
  &.cols-three{
    //padding: 0 0 40px;
  }
}
#overlay{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(76, 23, 0, 0.15);
  z-index: 900;
}
header{
  position: relative;
  padding: 44px 0 0;
  .container{
    display: block;
    padding: 22px 0 24px;
  }
  &:before{
    content: "";
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    height: 44px;
    z-index: 890;
    background: #fff;
    transition: all .15s;
  }
  &.fixed:before{
    box-shadow: 0 2px 16px rgba(174, 109, 33, 0.25);
  }
  .desc{
    display: none;
  }
}
#logo{
  display: block;
  position: fixed;
  height: 44px;
  top: 0;
  left: 8px;
  padding: 4px 8px;
  z-index: 891;
  &:hover{
    opacity: .7;
  }
  img{
    width: 126px;
    height: 35px;
  }
}
#contacts{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 8px;
  font-size: 15px;
  div{
    padding: 4px 0;
    white-space: nowrap;
    //overflow:hidden;
  }
  a.messenger{
    display: block;
    float: left;
    position: relative;
    width: 25px;
    text-align: center;
    top: -1px;
    font-size: 23px;
    line-height: 21px;
    color: $color-active;
    //outline: none;
    //z-index: 0;
    overflow: hidden;
    i{
      //position: relative;
      //z-index: 2;
    }
    /*span{
      display: block;
      position: absolute;
      left: -6px;
      top: -2px;
      opacity: 0;
      font-size: 15px;
      line-height: 30px;
      padding: 0 10px 0 32px;
      border-radius: 3px;
      background: #fff;
      z-index: 1;
      transition: none;
    }*/
    &:hover{
      color: $color-hover;
      /*z-index: 1;
      span{
        color: $color-hover;
        opacity: 1;
        @extend %box-shadow;
        transition: all .15s;
      }*/
    }
  }
  /*i{
    position: relative;
    top: 4px;
    font-size: 23px;
    line-height: 21px;
    color: #996036;
  }*/
  span{
    span{
      font-size: 18px;
    }
  }
}
.menu-input{
  display:none;
}
.menu-button{
  position: fixed;
  top: 0;
  right: 0;
  width: 58px;
  height: 44px;
  background: #fff;
  cursor: pointer;
  z-index: 920;
  & span, span:after, span:before {
    content: "";
    display: block;
    background: $color-active;
    border-radius: 1px;
    width: 21px;
    height: 2px;
    position: relative;
    transition: all .15s ease .1s;
  }
  span{
    margin: 0 auto;
    top: 21px;
  }
  span:before{
    top: -8px;
  }
  span:after{
    top: 6px;
    left: 7px;
    width: 14px;
  }
  &:hover span, &:hover span:after, &:hover span:before{
    background: $color-hover;
  }
  .menu-input:checked + &{
    background: transparent;
    transition: all .1s .1s;
    span {
      background-color: transparent;
    }
    span:before{
      top: 0;
      transform: rotate(135deg);
    }
    span:after{
      top: -2px;
      left: 0;
      width: 21px;
      transform: rotate(-135deg);
    }
  }
}

nav{
  position: fixed;
  top: 0;
  bottom: 0;
  right: -275px;
  width: 275px;
  z-index: 910;
  transition: right .2s ease;

  ul {
    padding: 15px 0 0;
  }
  a {
    display: block;
    font-size: 18px;
    padding: 9px 25px;
    &:hover{
      background: $color-hover-back-2;
    }
    &#more{
      display: none;
    }
    &.active{
      position: relative;
      color: $color-active;
      background: none!important;
      &:before{
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        border-radius: 0 3px 3px 0;
        background: $color-active;
      }
    }
  }
  .fav span{
    display: inline-block;
    position: relative;
    top: -9px;
    left: -6px;
    font-size: 13px;
    line-height: 15px;
    padding: 1px 4px 0;
    color: #fff;
    border-radius: 9px;
    background: $color-hover;
  }
  #menu-button:checked ~ &{
    right: 0;
  }
}
#wrap-drop {
  display: none;
}

.button-send{
  @extend %back-button-gradient;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 43px;
  z-index: 1000;
}



/*Поле поиска*/
#search{
  position: relative;
  display: flex;
  color: $color-active;
  margin: 0 8px 16px;
  transition: all .15s;
  &:hover{
    @extend %box-shadow;
  }
  i{
    position: absolute;
    width: 34px;
    font-size: 22px;
    line-height: 32px;
    text-align: right;
  }
  input{
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 36px;
    height: 36px;
    padding: 0 16px 0 42px;
    color: #4c1700;
    background: none;
    &::-webkit-input-placeholder {
      color: $color-active;
    }
    &::-ms-input-placeholder{
      color: $color-active;
    }
    &::placeholder{
      color: $color-active;
    }
  }
}

/*Фильтр*/
#filter{
  position: relative;
  margin: 0 8px 16px;
  transition: all .15s;
  &:hover{
    @extend %box-shadow;
  }
  h4{
    display: flex;
    font-size: 16px;
    line-height: 36px;
    font-weight: normal;
    padding: 0 16px;
    cursor: pointer;
    transition: all .15s;
    i{
      display: inline-block;
      line-height: 36px;
      vertical-align: middle;
      margin-left: 3px;
      transition: all .15s;
    }
  }
  &.open h4{
    color: $color-active;
    i{
      transform: scaleY(-1);
    }
  }
}
#opts{
  display: none;
  border-top: 1px solid $color-hover-back;
  //padding: 8px 0;
  #filter.open &{
    display: block;
  }
  > div{
    display: flex;
    flex-wrap: wrap;
    padding: 24px 16px;
    &:not(:last-of-type){
      border-bottom: 1px solid #f3ebd5;
    }
  }
  .sections{
    padding: 24px 12px 16px;
    input{
      display: none;
      /*&:checked + label:hover{
        color: $color-active;
        background: $color-hover-back;
      }*/
      &:checked + label{
        color: #fff;
        background: $color-active;
      }
      &:not(:checked) + label{
        //color: $color-active!important;
        color: $color-primary!important;
        background: $color-hover-back!important;
        &:hover{
          background: $color-border!important
        }
      }
      + label{
        float: left;
        margin: 0 4px 8px;
        line-height: 32px;
        padding: 0 16px;
        //color: $color-active;
        color: $color-primary;
        background: $color-hover-back;
        cursor: pointer;
        transition: all .15s;
        &.over{
          color: #fff;
          background: $color-active;
        }
        &:hover{
          //color: #fff!important;
          //background: $color-active!important;
          //box-shadow: 0 2px 16px rgba(174,109,33,.2);
          //background: $color-border
        }
      }
    }
  }
  .pravs {
    padding: 24px 8px 8px;
    .select-wrap{
      float: left;
      margin: 0 8px 16px;
      i{
        right: 8px;
        top: 2px;
      }
    }
    select{
      font-size: 16px;
      line-height: 32px;
      height: 32px;
      color: $color-primary;
      padding: 0 30px 0 16px;
    }
  }
  .years{
    padding: 18px 16px;
    > div{
      display: flex;
      height: 32px;
      align-items: center;
      //margin-bottom: 12px;
      margin: 6px 0;
      &:nth-child(2){
        width: 256px;
      }
    }
    .inputs{
      margin-right: 12px;
      //margin: 0 12px 12px;
      > *{
        margin-right: 8px;
      }
    }
    input{
      width: 59px;
      line-height: 32px;
      height: 32px;
      padding: 0 0 0 12px;
      color: $color-primary;
      background: $color-hover-back;
      transition: all .15s;
      &:focus, &.over{
        color: #fff;
        background: $color-active;
      }
    }
  }
  .clear{
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 36px;
    color: $color-active-filter;
    padding: 0 16px;
    transition: all .15s;
    &.active{
      visibility: visible;
      opacity: 1;
    }
    &:hover{
      color: $color-hover;
    }
  }
}

/*Преимущества*/
.why-wrap{
  display: none;
}

/*Сетка Masonry*/
.masonry{
  width: 100%!important;
  > div{
    width: 100%;
    float: left;
    padding: 0 8px 16px;
    transition: all .15s;
    > div{
      width: 100%;
    }
  }
}

/*Главная страница. Items */
.item-sect{
  padding: 16px 0 22px!important;
  transition: all .15s;
  >img{
    @extend %img-sect;
  }
  &:hover{
    @extend %box-shadow;
  }
  &.long-text{
    a{
      padding-right: 13px;
    }
    h3{
      font-size: 15px;
    }
  }
  h2{
    @extend %title-sect;
    color: $color-hover;
  }
  a{
    display: flex;
    align-items: center;
    padding: 5px 16px;
    img{
      flex: 0 0 55px;
      width: 55px;
      height: 55px;
      margin-right: 10px;
      transition: all .15s;
      &.round{
        border-radius: 28px;
      }
    }
    &.head{
      @extend %a-head;
      padding-right: 54px;
    }
    &:not(.head):hover{
      background: $color-hover-back-2;
    }
  }
  h3{
    //position: relative;
    //width: 100%;
    font-size: 16px;
    line-height: 125%;
    font-weight: normal;
  }
  &.one{
    padding: 0!important;
    a{
      padding: 21px 16px;
      &:before{
        top: 50%;
      }
      &:after{
        top: 50%;
        margin-top: -4px;
      }
    }
    >img{
      top: 50%;
      margin-top: -26px;
    }
  }
}
.item-plus{
  position: relative;
  padding: 21px 60px 21px 16px!important;
  cursor: pointer;
  transition: all .15s;
  &:hover{
    @extend %box-shadow;
  }
  h2{
    @extend %title-sect;
  }
  >img{
    @extend %img-sect;
    top: 50%;
    margin-top: -18px;
  }
}
.item-articles{
  padding: 16px 0 1px;
  a{
    display: flex;
    flex-direction: column;
    &.head{
      @extend %a-head;
      padding-right: 30px;
    }
    &:not(.head){
      padding: 16px 15px 10px;
      margin: 0 1px;
      &:hover{
        background: $color-hover-back;
      }
    }
    .wrap-img{
      display: block;
      width: 100%;
      padding-top: 52%; /* 25:12 Aspect Ratio */
      position: relative;
      margin-bottom: 5px;
    }
    img{
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
    h3{
      font-size: 16px;
      line-height: 125%;
      font-weight: normal;
    }
    &:last-child{
      padding-bottom: 25px;
      border-radius: 0 0 2px 2px;
    }
  }
  h2{
    @extend %title-sect;
    padding: 5px 16px;
  }
}

.wrap-text{
  padding: 16px 0 0;
  margin: 16px 0 20px;
  border-top: 1px solid $color-border;
}

/*Форма оценки — сквозная*/
@keyframes shake {
  0% {
    transform: translate(4px, 0);
  }
  50% {
    transform: translate(-4px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
#form{
  position: relative;
  z-index: 1;
  transition: all .15s, transform .3s;
  &.active, &:hover{
    //border-color: #fff;
    background: #fff;
    @extend %box-shadow;
  }
  &.animate{
    animation: shake .15s 2 linear;
  }
  >img{
    @extend %img-sect;
  }
  h2{
    @extend %title-sect;
    padding: 21px 60px 12px 16px;
  }
  form{
    padding: 0 8px;
  }
  fieldset{
    width: 100%;
    padding: 0 8px;
    min-inline-size: auto;
    /*div.validetta-error{
      input{
        box-shadow: inset 0 0 0 1px #fa2f3d;
      }
    }*/
    &:disabled{
      opacity: .7;
      pointer-events: none;
    }
    &.submit{
      margin: 8px 0 0;
    }
    &.files{
      label{
        position: relative;
        width: 100%;
        line-height: 36px;
        color: $color-active;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        display: block;
        margin: 8px 0;
        background: $color-hover-back;
        &:hover{
          color: #fff;
          background: $color-active;
        }
        input{
          display: none;
        }
        .icon-close{
          display: none;
        }
        &.nowrap{
          padding: 0 34px 0 10px;
          .icon-close{
            display: block;
          }
        }
        &.error+small{
          display: block;
          font-size: 14px;
          line-height: 14px;
          color: #f13e4b;
          padding: 0 0 6px 8px;
        }
      }
      .icon-close{
        position: absolute;
        top: 0;
        right: 0;
        height: 36px;
        line-height: 34px;
        font-size: 20px;
        padding: 0 10px;
        cursor: pointer;
      }
    }
    &.msg{
      display: none;
      text-align: center;
      padding: 8px 8px 0;
      h3{
        font-family: $family-head;
        font-weight: 500;
        margin: 8px 0 16px;
      }
      &.success{
        h3{
          color: #13a058;
        }
      }
      &.error{
        h3{
          color: #13a058;
        }
      }
      #resend{
        color: $color-active;
        background: $color-hover-back;
        &:hover{
          color: #fff;
          background: $color-active;
        }
      }
    }
  }
  input{
    width: 100%;
    line-height: 36px;
    height: 36px;
    color: $color-primary;
    padding: 0 10px;
    background: $color-hover-back;
    margin: 8px 0;
    &::-webkit-input-placeholder {
      color: $color-active-filter;
    }
    &::-ms-input-placeholder{
      color: $color-active-filter;
    }
    &::placeholder{
      color: $color-active-filter;
    }
    &.error{
      box-shadow: inset 0 0 0 1px #fa2f3d;
    }
    &+small{
      display: block;
      font-size: 14px;
      line-height: 14px;
      color: #f13e4b;
      padding: 0 0 6px 8px;
    }
  }
  button{
    width: 100%;
    font-size: 16px;
    line-height: 36px;
    //margin: 8px 0;
    color: $color-active;
    //background: #F3EBD5;
    &[type="submit"]{
      /*&:disabled{
        opacity: .7;
        pointer-events: none;
      }*/
      &:hover{
        //transform: translateY(-2px);
        box-shadow: 0 3px 16px rgba(174,109,33,.25),0 1px 4px rgba(174,109,33,.25);
      }
    }
  }
  .text{
    color: $color-active;
    padding: 12px 0 20px;
    >div{
      text-align: center;
      line-height: 27px;
      padding: 5px 0;
    }
  }
  .contacts{
    .phone{
      color: $color-primary;
      span{
        font-size: 18px;
      }
    }
    a{
      display: inline-block;
      width: 27px;
      text-align: center;
      font-size: 25px;
      line-height: 21px;
      padding: 0;
      color: $color-active;
      background: none;
      &:hover{
        color: $color-hover;
      }
    }
  }
  .email{
    padding: 0;
    a{
      display: inline-block;
      font-size: 18px;
      //color: $color-active;
      padding: 0;
      background: none;
      &:hover{
        //color: $color-hover;
      }
    }
  }
  #ld {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95px;
    height: 36px;
    margin: auto;
    i {
      display: block;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #fff;
      &:nth-child(1) {
        animation: ld 1s ease infinite 0s; }
      &:nth-child(2) {
        animation: ld 1s ease infinite 0.1s; }
      &:nth-child(3) {
        animation: ld 1s ease infinite 0.2s; }
      &:nth-child(4) {
        animation: ld 1s ease infinite 0.3s; }
    }
  }


}
@keyframes ld {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

//Царские монеты и другие разделы
.item-prav {
  float: left;
  //padding: 0 0 12px;
  overflow: hidden;
  transition: all .15s;
  &:hover{
    @extend %box-shadow;
  }
  a.head{
    display: block;
    position: relative;
    overflow: hidden;
    //border-radius: 3px 3px 0 0;
    .line{
      @extend %line-hover;
      bottom: 0;
    }
    @extend %head-prav;
    h2{
      &:before{
        @extend %line-before;
        bottom: 11px;
      }
      &:after{
        @extend %line-after;
        bottom: 7px;
      }
      &.big{
        //width: 200px;
        + span{
          bottom: 70px;
        }
      }
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -100%;
      bottom: 0;
      width: 100%;
      background: linear-gradient(90deg, rgba(43, 13, 0, 0) 0, rgba(43, 13, 0, .9) 100%);
      z-index: 1;
      transition: all .15s ease;
    }
    &:hover{
      .line{
        left: 0;
      }
      &:after{
        right: 0;
      }
      h2{
        &:before{
          @extend %line-before-hover;
        }
        &:after{
          @extend %line-after-hover;
        }
      }
    }
  }

  .years{
    display: flex;
    flex-wrap: wrap;
    padding: 14px 7px 8px;
    a{
      display: block;
      width: 20%;
      line-height: 27px;
      padding: 0 5px;
      text-align: center;
      border-radius: 3px;
      &:hover{
        background: $color-hover-back-2;
      }
    }
  }
  .variety{
    padding: 0 0 8px;
    a{
      display: block;
      position: relative;
      line-height: 21px;
      padding: 3px 16px;
      overflow: hidden;
      &:hover{
        background: $color-hover-back-2;
      }
    }
  }
  .nominals{
    float: left;
    padding: 0 7px 8px;
    a{
      display: block;
      float: left;
      line-height: 21px;
      padding: 3px 8px;
      //text-align: center;
      border-radius: 3px;
      &:hover{
        background: $color-hover-back-2;
      }
      &:before{
        display: inline-block;
        content: '';
        position: relative;
        top: -3px;
        width: 4px;
        height: 4px;
        border-radius: 3px;
        margin-right: 6px;
        background: $color-border;
      }
    }
  }
  div:first-of-type{
    margin-top: 16px;
  }
  div:last-of-type{
    margin-bottom: 12px;
  }
}
.item-inostr {
  transition: all .15s;
  &:hover{
    @extend %box-shadow;
  }
  a.head{
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    .line{
      @extend %line-hover;
      bottom: 0;
    }
    &:before{
      @extend %line-before;
      bottom: 36px;
    }
    &:after{
      @extend %line-after;
      bottom: 32px;
    }
    &:hover{
      .line{
        left: 0;
      }
      &:before{
        @extend %line-before-hover;
      }
      &:after{
        @extend %line-after-hover;
      }
    }
  }
  img {
    position: absolute;
    display: block;
    top: -10px;
    left: 14px;
    width: 105px;
    height: 105px;
  }
  h2{
    position: relative;
    font-family: "Noto Serif Medium";
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    padding: 108px 50px 24px 16px;
  }
}
.item-banknota {
  img {
    top: 24px;
  }
  h2{
    padding: 144px 50px 24px 16px;
  }
}
.item-znak{
  position: relative;
  overflow: hidden;
  padding: 0 0 12px;
  transition: all .15s;
  &:hover{
    @extend %box-shadow;
  }
  a.head{
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    .line{
      @extend %line-hover;
      top: 0;
    }
    &:before{
      @extend %line-before;
      top: 34px;
    }
    &:after{
      @extend %line-after;
      top: 30px;
    }
    &:hover{
      .line{
        left: 0;
      }
      &:before{
        @extend %line-before-hover;
      }
      &:after{
        @extend %line-after-hover;
      }
    }
  }
  img {
    position: absolute;
    display: block;
    bottom: 16px;
    right: -35px;
    width: 105px;
    height: 105px;
  }
  h2{
    position: relative;
    font-family: "Noto Serif Medium";
    font-size: 19px;
    line-height: 25px;
    font-weight: 500;
    padding: 22px 50px 12px 16px;
  }
  .variety{
    padding: 0 0 8px;
    a{
      display: block;
      position: relative;
      line-height: 21px;
      padding: 3px 16px;
      overflow: hidden;
      &:hover{
        background: rgba(227, 219, 159, 0.25);
      }
      &.metal{
        padding: 3px 16px 3px 33px;
      }
    }
  }
}


.item-product{
  float: left;
  line-height: 125%;
  transition: all .15s;
  &:hover{
    @extend %box-shadow;
  }
  a.head{
    display: block;
    width: 100%;
    float: left;
    overflow: hidden;
    border-radius: 3px 3px 0 0;
    &:hover{
      img{
        //opacity: .85;
      }
    }
  }
  h2{
    width: 100%;
    float: left;
    //font-family: $family-stack;
    font-family: $family-head;
    font-size: 19px;
    line-height: 125%;
    font-weight: 500;
  }
  img{
    display: block;
    height: auto;
    max-height: 350px;
    //float: left;
    //transition: all .15s;
  }
  .excerpt{
    width: 100%;
    float: left;
    font-size: 14px;
    padding: 10px 16px 12px;
    color: $color-active;
  }
  .price{
    display: block;
    float: left;
    font-size: 17px;
    padding: 0 16px 20px;
    &.none{
      font-size: 16px;
      color: $color-active;
    }
  }
  .fav-add {
    @extend %fav-add;
    right: 8px;
    bottom: 23px;
    color: $color-active-filter;
  }
  &.coin{
    a.head{
      padding: 16px 14px 0;
    }
    h2{
      padding: 20px 0 0 2px;
    }
    img{
      width: 49.5%;
      float: left;
      + img{
        float: right;
      }
    }

  }
  &.other{
    a.head{
      padding: 0;
    }
    h2{
      padding: 20px 10px 0 16px;
    }
    img{
      width: auto;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  &.nominal{
    a.head{
      padding: 5px;
      &:hover{
        @extend %box-shadow
      }
      &:hover{
        h2:before{
          @extend %line-before-hover;
        }
        h2:after{
          @extend %line-after-hover;
        }
      }
    }
    h2{
      width: auto;
      float: none;
      margin-right: -5px;
      padding: 8px 48px 8px 11px;
      position: relative;
      &:before{
        @extend %line-before;
        //top: 19px;
        bottom: 19px;
      }
      &:after{
        @extend %line-after;
        //top: 15px;
        bottom: 15px;
      }
    }
    img{
      width: 100%;
      height: auto;
      &+h2{
        padding-bottom: 16px;
        &:before{
          bottom: 27px;
        }
        &:after{
          bottom: 23px;
        }
      }
    }
  }
}
.item-article{
  float: left;
  line-height: 125%;
  transition: all .15s;
  &:hover{
    @extend %box-shadow;
  }
  a.head{
    display: block;
    width: 100%;
    float: left;
    overflow: hidden;
    border-radius: 3px 3px 0 0;
    &:hover{
      img{
        //transform: scale(1.15);
      }
      h2{
        //text-decoration: underline;
      }
    }
  }
  h2{
    width: 100%;
    float: left;
    //font-family: $family-stack;
    font-family: $family-head;
    font-size: 19px;
    line-height: 125%;
    font-weight: 500;
    padding: 18px 10px 0 16px;
  }
  img{
    display: block;
    width: 100%;
    height: auto;
    //transition: all .15s;
  }
  .excerpt{
    width: 100%;
    float: left;
    font-size: 14px;
    padding: 10px 16px 20px;
    color: $color-active;
  }
  .date{
    float: left;
    font-size: 14px;
    margin: 18px 10px -12px 16px;
    color: $color-active;
  }
}
.years-wrap{
  width: 100%;
  float: left;
  padding: 0 4px;
  margin: 0 0 8px;
  a{
    display: block;
    float: left;
    line-height: 21px;
    padding: 6px 16px 5px;
    margin: 0 4px 8px;
    background: #fff;
    border-radius: 3px;
    &:hover{
      @extend %box-shadow
    }
  }
}
.cattext-wrap{
  width: 100%;
  float: left;
  padding: 0 8px;
  margin: 0 0 16px;
  .cattext{
    padding: 20px 16px 16px;
    border-radius: 3px;
    @include border;
  }
  p{
    margin: 0 0 8px;
  }
  .cat-photo{
    border-radius: 3px;
    overflow: hidden;
    margin: 0 0 16px;
    img{
      width: 100%;
      height: auto;
    }
  }
}

section.left{
  .breadcrumbs{
    margin: 0 19px 16px!important;
  }
}
.breadcrumbs{
  display: flex;
  flex-wrap: wrap;
  color: $color-active-filter;
  font-size: 14px;
  //margin: 0 19px 6px;
  margin: 0 19px 14px;
  a{
    color: $color-active;
    padding: 0 5px;
    &:hover{
      color: $color-hover;
    }
  }
  span{
    padding: 0 5px;
  }
}

.control{
  display: flex;
  justify-content: space-between;
  color: $color-active;
  font-size: 14px;
  padding: 0 8px;
  margin: 0 0 16px;
  &.top{
    .pagination{
      display: none;
    }
  }
  &.bottom{
    .onpage{
      display: none;
    }
  }
}
.list-articles{
  .control.top{
    display: none;
  }
}
.pagination{
  display: flex;
  flex: 1 0;
  margin: 0 -1px;
  a{
    min-width: 28px;
    line-height: 26px;
    padding: 2px 8px 0;
    margin: 0 1px;
    color: $color-active;
    text-align: center;
    border-radius: 3px;
    white-space: nowrap;
    &.active{
      background: $color-hover-back;
    }
    &:hover{
      background: $color-hover-back;
      color: $color-hover;
    }
    i{
      display: inline-block;
      position: relative;
      top: 1px;
    }
    span{
      display: none;
    }
    &.prev{
      i{
        transform: rotate(90deg);
      }
    }
    &.next{
      i{
        transform: rotate(-90deg);
      }
    }
  }
}
.sorting{
  flex: 1;
  @include border;
  h4{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    padding: 0 16px;
    cursor: pointer;
    transition: all .15s;
    i{
      line-height: 28px;
      display: inline-block;
      vertical-align: middle;
      margin-right: -3px;
      transition: all .15s;
    }
  }
  &.open{
    h4 i{
      transform: scaleY(-1);
    }
  }
  > div{
    display: none;
    padding: 2px 7px 7px;
    overflow: hidden;
  }
  a{
    display: block;
    float: left;
    clear: both;
    color: $color-active;
    margin: 1px;
    white-space: nowrap;
    transition: none;
    span{
      display: inline-block;
      line-height: 28px;
      padding: 0 8px;
      border-radius: 3px;
      transition: all .15s;
    }
    i{
      display: none;
      width: 28px;
      font-size: 23px;
      line-height: 28px;
      text-align: center;
      vertical-align: middle;
      margin-left: 2px;
      border-radius: 3px;
      transition: all .15s;
    }
    &.active{
      span{
        background: $color-hover-back;
      }
      i{
        display: inline-block;
        &.active, &:hover{
          display: inline-block;
          background: $color-hover-back;
        }
        &:hover{
          color: $color-hover;
        }
      }
    }
    &:hover{
      span{
        color: $color-hover;
        background: $color-hover-back;
      }
    }
  }
}
.onpage{
  display: flex;
  margin-left: 8px;
  span{
    line-height: 28px;
    margin-right: 7px;
    white-space: nowrap;
  }
}

/* Карточка монеты и т.п. */
.product{
  padding: 0 8px 16px;
  > div{
    float: left;
    position: relative;
  }
  h1{
    padding: 20px 50px 16px 15px;
  }
  .photos-wrap{
    padding: 0 16px 16px;
  }
  #photos{
    a{
      display: block;
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
    }
    img{
      //display: block;
      width: 100%;
      //max-width: 350px;
      height: auto;
      //margin: 0 auto;
      cursor: pointer;
      transition: transform .3s;
      &.hidden{
        position: absolute;
        left: 50%;
        //visibility: hidden;
        opacity: 0;
        margin-left: -175px;
        transform: rotateY(90deg);
      }
      &.animate{
        transform: rotateY(90deg);
      }
    }
  }
  #turn{
    display: block;
    margin: 8px auto 0;
    font-size: 14px;
    line-height: 32px;
    padding: 0 16px 0 10px;
    color: $color-active;
    transition: all .15s;
    i{
      //display: inline-block;
      position: relative;
      top: 4px;
      font-size: 22px;
      //transition: all .6s;
    }
    &:hover{
      color: $color-hover;
      i{
        //transform: rotate(360deg);
      }
    }
  }
  #thumbs{
    display: none;
    img{
      width: 50%;
      float: left;
      max-width: 175px;
      height: auto;
      padding: 8px;
      border-radius: 3px;
      border: 1px solid $color-border;
      //opacity: .85;
      transition: all .15s .15s, opacity .15s;
      &.hidden{
        opacity: .85;
        border-color: transparent;
        cursor: pointer;
      }
      &:hover{
        opacity: 1;
      }
    }
  }
  .desc{
    padding: 0 16px 8px;
    transition: all .3s;
    &.hidden{
      height: 450px;
      overflow: hidden;
      &:after{
        content: '';
        height: 160px;
        left: 0;
        right: 0;
        position: absolute;
        bottom: 0;
        background: linear-gradient(0, #fff 30%, rgba(255,255,255,0) 100%);
        pointer-events: none;
        z-index: 1;
        //border-radius: 0 0 10px 10px;
        transition: all .3s ease;
      }
    }
    &:not(.hidden){
      #more{
        display: none;
      }
    }
    #more{
      @extend %more
    }
    .prices{
      margin: 0 0 8px;
      .price{
        color: $color-hover;
        margin: 0 0 16px -1px;
        &.none{
          font-size: 19px;
          color: $color-active;
        }
      }
      p{
        //line-height: 18px;
        //margin: 0 0 12px;
        margin: 0 0 16px;
      }
      a{
        display: inline-block;
        position: relative;
        font-size: 14px;
        padding: 8px;
        margin-left: -8px;
        color: $color-hover;
        &:after{
          display: inline-block;
          content: "\e907";
          font-family: Icons;
          line-height: 20px;
          vertical-align: middle;
          transform: rotate(-90deg);
          margin-left: -3px;
          opacity: 0;
          transition: opacity,margin-left .15s;
        }
        &:hover{
          color: $color-hover;
          text-decoration: underline;
          &:after{
            opacity:1;
            margin-left: 3px;
          }
        }
      }
    }
    .specs{
      .metal{
        margin: 0 0 16px;
      }
      ul{
        margin: 0 0 16px;
      }
      li{
        //line-height: 18px;
        margin: 0 0 5px;
      }
      h3{
        font-size: 16px;
        margin: 0 0 8px;
      }
    }
    .sides{
      /*.side{
        margin: 0 0 16px;
      }*/
      h2{
        font-size: 19px;
        margin: 16px 0 8px;
      }
      p{
        margin: 0 0 8px;
      }
    }
  }
  table{
    width: 100%;
    margin: 1px 1px 0 0;
    tbody{
      display: flex;
      width: 100%;
      justify-content: stretch;
    }
    tr{
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      &.safe{
        color: $color-active;
      }
    }
    td{
      margin: 0 0 -1px -1px;
    }
  }
  .fav-add {
    @extend %fav-add;
    top: 11px;
    right: 4px;
    color: $color-active;
    span{
      display: none;
    }
  }
}
.share-wrap{
  width: 100%;
  float: left;
  padding: 0 8px 5px;
}
.share{
  padding: 16px;
  > span{
    display: block;
    color: $color-active;
    margin-bottom: 6px;
  }
}
.form-wrap{
  width: 100%;
  float: left;
  margin: 0 0 16px;
  padding: 0 8px;
}
.pluses-wrap{
  width: 100%;
  float: left;
  > div{
    padding: 0 8px;
    margin: 0 0 16px;
  }
}
.related{
  width: 100%;
  float: left;
  //margin: 0 0 16px;
  h2{
    @extend %h2;
  }
  a{
    display: block;
    font-size: 18px;
    padding: 16px 55px 16px 16px;
    transition: all .15s;
    &:hover{
      @extend %box-shadow
    }
    @extend %a-head;
    &:before{
      top: 50%;
    }
    &:after{
      top: 50%;
      margin-top: -4px;
    }
  }
}
.similar-wrap{
  width: 100%;
  float: left;
  //margin: 0 0 16px;
  h2{
    @extend %h2;
  }
}
.viewed-wrap{
  width: 100%;
  float: left;
  //margin: 0 0 16px;
  h2{
    @extend %h2;
  }
}

.about-wrap {
  width: 100%;
  float: left;
  padding: 0 8px;
  margin: 0 0 16px;
}
.about-photo{
  position: relative;
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  @extend %head-prav;
  &.no-gr:before{
    display: none;
  }
}
.about-text{
  position: relative;
  padding: 20px 16px 52px;
  margin: -1px 0 0;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  @include border;
  &.small{
    padding-bottom: 16px;
  }
  &.hidden{
    height: 242px;
    padding: 20px 16px 16px;
    &:after{
      content: "";
      height: 160px;
      left: 1px;
      right: 1px;
      position: absolute;
      bottom: 0;
      border-radius: 2px;
      //background: linear-gradient(0, #F8F6E8 29%, rgba(248, 246, 232, 0) 100%);
      background: linear-gradient(to top, rgba(248,246,232,1) 29%,rgba(248,246,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8f6e8",endColorstr="#f8f6e8",GradientType=1);
      pointer-events: none;
      z-index: 1;
      border-bottom: 1px solid #e6cfb0;
      transition: all .3s ease;
    }
  }
  #more {
    @extend %more;
    font-size: 14px;
    &.open{
      i{
        transform: scaleY(-1);
      }
    }
  }
    p{
    font-size: 14px;
    line-height: 140%;
    margin: 0 0 4px;
  }
}

article{
  margin: 0 8px 16px;
  padding: 0 0 12px;
  .share{
    display: flex;
    flex-wrap: wrap;
    padding: 8px 16px 8px;
    > span{
      float: left;
      line-height: 24px;
      margin: 4px 0;
    }
    .ya-share2 {
      float: left;
      margin: 4px 16px 4px 8px;
    }
  }
  h1{
    padding: 20px 15px 8px;
  }
  h2{
    font-family: $family-head;
    font-size: 24px;
    line-height: 27px;
    font-weight: 500;
    padding: 8px 15px 16px;
  }
  h3{
    font-family: $family-head;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    padding: 4px 15px 14px;
  }
  p{
    padding: 0 16px 16px;
    > img{
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;
      margin: 8px auto 4px;
    }
  }
  > ul{
    margin: -6px 32px 16px;
    li {
      margin: 0 0 4px;
      &:before{
        content: "\2981";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $color-active; /* Change the color */
        //font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }
    }
  }
  a{
    color: $color-hover;
    &:hover{
      text-decoration: underline;
    }
  }
  blockquote{
    position: relative;
    font-style: italic;
    p{
      //color: $color-active;
      padding: 0 32px 16px;
    }
    &:before, &:after{
      display: block;
      position: absolute;
      font-family: $family-head;
      color: $color-border;
      font-size: 32px;
      //padding: 0 16px 16px;
    }
    &:before{
      content: '«';
      left: 8px;
      top: -4px;
    }
    &:after{
      content: '»';
      right: 14px;
      bottom: 20px;
    }
  }
  figure{
    width: 100%;
    float: left;
    margin: 8px 0 4px;
    img{
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
    &:first-of-type{
      margin-bottom: 16px;
    }
    &.padding{
      img{
        padding: 0 16px;
      }
    }
    &.cols-2, &.cols-3{
      figcaption{
        padding: 12px 16px 10px;
        > div{
          margin: 0 0 6px;
        }
      }
    }
    /*&.head{
      img{
        border-radius: 3px 3px 0 0;
      }
    }*/
  }
  figcaption{
    width: 100%;
    float: left;
    font-size: 14px;
    line-height: 125%;
    color: $color-active;
    padding: 12px 16px 16px;
  }
  .table-responsive{
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    > table{
      margin: 0 16px 20px;
      &.left{
        td{
          text-align: left;
        }
      }
      &.no-border{
        td{
          border-color: transparent;
        }
      }
    }
  }
  > table{
    width: auto;
    margin: 0 16px 20px;
    &.left{
      td{
        text-align: left;
      }
    }
    &.no-border{
      td{
        border-color: transparent;
      }
    }
  }
}

footer {
  margin-top: auto;
  border-top: 1px solid $color-border;
  .container{
    padding: 30px 8px 80px;
  }
  ul{
    margin-bottom: 16px;
    a{
      display: inline-block;
      line-height: 27px;
      padding: 0 16px;
    }
  }
  p{
    font-size: 14px;
    color: $color-active;
    margin: 0 16px;
    &.mail{
      margin-top: 16px;
    }
  }
}
//@import '/node_modules/photoswipe/dist/photoswipe.css';
//@import '/node_modules/photoswipe/dist/default-skin/default-skin.css';
@import 'photoswipe.css';
@import 'photoswipe-default-skin.css';

@media screen and (min-width: 360px) {
  .onpage {
    margin-left: 15px;
  }
}
@media screen and (min-width: 415px) {
  .product{
    table{
      min-width: 350px;
      width: auto;
    }
  }
}
@import '640';
@import '768';
@import '992';
@import '1200';
@import '1340';