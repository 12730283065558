@media screen and (min-width: 992px) {
  body {
    background-position: 116px 54px;
  }
  .masonry{
    > div {
      width: 33.33%;
    }
  }
  .container{
    &.text {
      column-count: 4;
    }
  }
  section.center{
    //width: 100%;
  }

  .control{
    &.top{
      .pagination{
        display: flex;
        margin-right: auto;
      }
      .sorting{
        flex: 0 1 auto;
      }
    }
  }
  .sorting{
    flex: 0 1 auto;
  }
  .product {
    .desc{
      .specs{
        width: 100%;
        float: left;
        h3{
          width: 50%;
          float: left;
          margin-top: -38px;
        }
        ul{
          width: 50%;
          float: left;
          padding-right: 16px;
        }
      }
    }
  }

  footer{
    .container{
      >div{
        width: 75%;
      }
    }
    ul{
      width: 25%;
    }
  }
}
