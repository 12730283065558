@media screen and (min-width: 640px) {
  body{
    background-position: 100px 59px;
  }
  .container{
    &.text{
      column-count: 2;
    }
  }
  .menu-button {
    display: none;
  }
  nav{
    width: 100%;
    right: 0;
    bottom: auto;
    ul.menu{
      display: flex;
      justify-content: space-between;
      padding: 0 22px;
      li{
        &.more{
          display: none;
        }
      }
      a{
        font-size: 16px;
        padding: 5px 8px;
        margin: 5px 2px;
        white-space: nowrap;
        border-radius: 3px;
        &.active{
          &:before{
            left: 0;
            top: -5px;
            right: 0;
            bottom: auto;
            width: auto;
            height: 3px;
            border-radius: 0 0 3px 3px;
          }
        }
      }
    }
    a#more-nav{
      display: block;
      width: 58px;
      position: relative;
      padding: 5px 18px 5px 8px;
      margin-right: 5px;
      &.opened{
        background: #f8f6e8;
      }
      .icon-more{
        font-size: 22px;
        line-height: 0;
        position: relative;
        top: 4px;
        left: -1px;
      }
    }
  }

  #wrap-drop{
    position: absolute;
    width: 307px;
    top: 42px;
    right: 0;
    padding: 0 16px 16px;
    z-index: 920;
    overflow-y: hidden;
  }
  #more-drop {
    padding: 10px 0;
    background: #fff;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 2px 16px rgba(174, 109, 33, 0.25);
    a{
      display: block;
      font-size: 16px;
      padding: 7px 25px;
      margin: 0;
    }
  }
  header{
    width: 100%;
    &:before{
      height: 42px;
    }
    .container{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      //padding: 22px 0 8px;
    }
  }
  #logo{
    height: 49px;
    left: 16px;
    position: relative;
    background: url(/catalog/view/theme/custom/img/logo-back.svg) no-repeat 8px 4px;
    background-size: 148px 41px;
    &:hover{
      transform: scale(1.05);
      opacity: 1;
    }
    img{
      width: 148px;
      height: 41px;
    }
  }
  #contacts {
    padding: 0 24px 0;
  }
  /*Сетка Masonry*/
  .masonry{
    > div{
      width: 50%;
    }
  }
  .control {
    padding: 0 16px;
    &.top{
      margin: -8px 0 16px;
    }
  }
  .sorting{
    box-shadow: none;
    h4{
      display: none;
    }
    > div{
      display: block!important;
      padding: 0;
      margin: 0 -1px;
    }
    a{
      clear: none;
      margin: 0 8px 0 1px;
      span{
        //padding: 0 16px;
      }
    }
  }
  .pagination{
    a{
      span{
        display: inline;
      }
      &.prev{
        padding: 2px 11px 0 9px;
        i{
          left: -3px;
        }
      }
      &.next{
        padding: 2px 9px 0 11px;
        i{
          right: -3px;
        }
      }
    }
  }
  article{
    figure{
      &.cols-2, &.cols-3{
        figcaption{
          padding: 12px 8px 16px;
          > div{
            float: left;
            padding: 0 8px;
            margin: 0;
            &:before{
              display: none;
            }
          }
        }
      }
      &.cols-2{
        figcaption{
          > div{
            width: 50%;
          }
        }
      }
      &.cols-3{
        figcaption{
          > div{
            width: 33.33%;
          }
        }
      }
    }
  }



}
@media screen and (min-width: 640px) and (max-width: 767px) {
  .form-wrap{
    width: 50%;
    float: left;
    //padding: 8px;
  }
  .pluses-wrap{
    width: 50%;
    float: left;
    //padding: 8px;
  }
}
@media screen and (min-width: 640px) and (max-width: 1339px) {
  .about-wrap {
    padding: 0;
  }
  .about-photo-wrap{
    width: 50%;
    float: left;
    padding: 0 8px;
  }
  .about-text-wrap{
    width: 50%;
    float: left;
    padding: 0 8px;
  }
  .about-photo{
    height: 260px;
    border-radius: 3px;
    @include border;
    &:before{
      display: none;
    }
    h2{
      position: static;
      color: $color-primary;
      padding: 16px 16px 6px;
    }
    span{
      position: static;
      color: $color-active;
      padding: 0 16px;
      left: auto;
      bottom: auto;
    }
  }
  .about-text{
    margin: 0;
    border-radius: 3px;
    &.hidden{
      height: 260px;
    }
  }
}