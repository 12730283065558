@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Istok+Web:400,400i,700&display=swap");
@import url(photoswipe.css);
@import url(photoswipe-default-skin.css);
.button-send {
  display: block;
  font-size: 18px;
  text-align: center; }

.tooltip, #search:hover, #filter:hover, .item-sect:hover, .item-plus:hover, #form.active, #form:hover, .item-prav:hover, .item-inostr:hover, .item-znak:hover, .item-product:hover, .item-product.nominal a.head:hover, .item-article:hover, .years-wrap a:hover, .related a:hover {
  box-shadow: 0 4px 16px rgba(174, 109, 33, 0.2); }

.item-sect > img, .item-plus > img, #form > img {
  position: absolute;
  top: 16px;
  right: 21px;
  width: 36px;
  height: 36px;
  transition: all .15s; }

.item-sect h2, .item-plus h2, .item-articles h2, #form h2 {
  font-family: "Noto Serif Medium";
  font-weight: 500;
  font-size: 20px;
  line-height: 125%; }
  .item-sect h2 > span, .item-plus h2 > span, .item-articles h2 > span, #form h2 > span {
    font-family: "Noto Serif Medium";
    white-space: nowrap; }

.item-sect a.head:before, .item-articles a.head:before, .related a:before, .item-prav a.head h2:before, .item-inostr a.head:before, .item-znak a.head:before, .item-product.nominal h2:before {
  content: "";
  position: absolute;
  right: 47px;
  height: 1px;
  width: 5px;
  background: #F24700;
  opacity: 0;
  z-index: 1;
  transition: all .25s; }

.item-sect a.head:hover:before, .item-articles a.head:hover:before, .related a:hover:before, .item-prav a.head:hover h2:before, .item-inostr a.head:hover:before, .item-znak a.head:hover:before, .item-product.nominal a.head:hover h2:before {
  width: 22px;
  right: 19px;
  opacity: 1; }

.item-sect a.head:after, .item-articles a.head:after, .related a:after, .item-prav a.head h2:after, .item-inostr a.head:after, .item-znak a.head:after, .item-product.nominal h2:after {
  content: "";
  position: absolute;
  right: 47px;
  height: 9px;
  width: 9px;
  border-top: 1px solid #F24700;
  border-right: 1px solid #F24700;
  transform: rotate(45deg);
  opacity: 0;
  transition: all .25s; }

.item-sect a.head:hover:after, .item-articles a.head:hover:after, .related a:hover:after, .item-prav a.head:hover h2:after, .item-inostr a.head:hover:after, .item-znak a.head:hover:after, .item-product.nominal a.head:hover h2:after {
  right: 19px;
  opacity: 1; }

.item-sect a.head, .item-articles a.head, .related a {
  position: relative;
  z-index: 1; }
  .item-sect a.head + img + a, .item-articles a.head + img + a, .related a + img + a {
    margin-top: 10px; }
  .item-sect a.head:before, .item-articles a.head:before, .related a:before {
    top: 17px; }
  .item-sect a.head:after, .item-articles a.head:after, .related a:after {
    top: 13px; }
  .item-sect a.head:hover + img, .item-articles a.head:hover + img, .related a:hover + img {
    right: 13px;
    opacity: 0; }

.item-product .fav-add, .product .fav-add {
  position: absolute;
  font-size: 26px;
  padding: 12px;
  cursor: pointer;
  transition: all .15s; }
  .item-product .active.fav-add, .product .active.fav-add {
    color: #F24700; }
    .item-product .active.fav-add:before, .product .active.fav-add:before {
      content: "\e90d"; }
  .item-product .fav-add:hover, .product .fav-add:hover {
    color: #F24700; }

.item-prav a.head .line, .item-inostr a.head .line, .item-znak a.head .line {
  display: block;
  position: absolute;
  left: -100%;
  height: 2px;
  width: 100%;
  background: #F24700;
  z-index: 2;
  transition: all .15s ease-out; }

.item-prav a.head img, .about-photo img {
  display: block;
  width: 100%;
  height: auto; }

.item-prav a.head:before, .about-photo:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(90deg, rgba(43, 13, 0, 0.6) 0, rgba(43, 13, 0, 0) 100%);
  z-index: 1; }

.item-prav a.head span, .about-photo span {
  position: absolute;
  left: 16px;
  bottom: 44px;
  color: #fff;
  opacity: 0.5;
  z-index: 2; }

.item-prav a.head h2, .about-photo h2 {
  position: absolute;
  left: 16px;
  right: 0;
  bottom: 16px;
  color: #fff;
  font-family: "Noto Serif Medium";
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  padding-right: 50px;
  z-index: 2; }

.product .desc #more, .about-text #more {
  position: absolute;
  left: 0;
  bottom: 6px;
  padding: 16px;
  z-index: 2;
  cursor: pointer;
  transition: all .15s; }
  .product .desc #more i, .about-text #more i {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 2px; }
  .product .desc #more:hover, .about-text #more:hover {
    color: #F24700; }

@font-face {
  font-family: 'Noto Serif Medium';
  src: url("/catalog/view/theme/custom/fonts/notoserif-medium.woff2") format("woff2"), url("/catalog/view/theme/custom/fonts/notoserif-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Icons';
  src: url("/catalog/view/theme/custom/fonts/Icons.ttf?7wl8vw") format("truetype"), url("/catalog/view/theme/custom/fonts/Icons.woff?7wl8vw") format("woff"), url("/catalog/view/theme/custom/fonts/Icons.svg?7wl8vw#Icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.icon-turn:before {
  content: "\e90e"; }

.icon-fav-active:before {
  content: "\e90d"; }

.icon-sort-aya:before {
  content: "\e908"; }

.icon-sort-yaa:before {
  content: "\e909"; }

.icon-sort-19:before {
  content: "\e90a"; }

.icon-sort-91:before {
  content: "\e90b"; }

.icon-select:before {
  content: "\e90c"; }

.icon-close:before {
  content: "\e904"; }

.icon-fav:before {
  content: "\e905"; }

.icon-search:before {
  content: "\e906"; }

.icon-arrow:before {
  content: "\e907"; }

.icon-more:before {
  content: "\e903"; }

.icon-telegram:before {
  content: "\e900"; }

.icon-viber:before {
  content: "\e901"; }

.icon-whatsapp:before {
  content: "\e902"; }

* {
  font-family: "Istok Web", sans-serif; }

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  background: none; }

button {
  font-size: 16px;
  transition: all .15s ease;
  cursor: pointer; }

input {
  font-size: 16px; }

i[class*=" icon-"], i[class^=icon-] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

ul {
  list-style: none; }

a {
  color: #4C1700;
  text-decoration: none;
  transition: all .15s; }
  a:hover {
    color: #F24700; }

h1, h2 {
  font-family: "Noto Serif Medium";
  line-height: 115%;
  font-weight: 500;
  position: relative; }

h1 {
  font-size: 28px; }

h2 {
  font-size: 24px; }

.related h2, .similar-wrap h2, .viewed-wrap h2 {
  font-family: "Noto Serif Medium";
  font-size: 24px;
  line-height: 115%;
  font-weight: 500;
  padding: 16px 23px 22px; }

h1 img, section.center h2 img {
  position: absolute;
  right: 21px;
  width: 36px;
  height: 36px; }

.white {
  background: #fff; }

.border {
  box-shadow: inset 0 0 0 1px rgba(183, 105, 33, 0.28); }

.round {
  border-radius: 3px; }

.primary {
  color: #fff !important;
  background: linear-gradient(90deg, #FF5308 0%, #F30874 50%, #C20059 100%);
  background-size: 200%; }
  .primary:hover {
    background-position: 100% 0; }

[data-hide]:before {
  content: attr(data-hide);
  padding: 0 5px 0 0; }

/* Для Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

/* Для Firefox */
input[type=number] {
  -moz-appearance: textfield; }

/* Значек Рубля — c нестандартными шрифтами необязателен */
/*i.ruble{
  &:before{
    content: "Р";
    font-style: normal;
    width: 0.5em;
  }
  &:after{
    content: "−";
    position: relative;
    left: -.8em;
    top: .13em;
    font-size: .9em;
    width: .5em;
  }
}*/
/* Tooltip */
.tooltip {
  position: fixed;
  display: none;
  max-width: 250px;
  padding: 5px 12px !important;
  color: #996036 !important;
  line-height: initial !important;
  background: #fff;
  border-radius: 3px;
  z-index: 1000; }

.select-wrap {
  display: inline-block;
  position: relative; }
  .select-wrap select {
    font-size: 14px;
    line-height: 28px;
    height: 28px;
    color: #996036;
    padding: 0 20px 0 8px;
    background: #F3EBD5;
    border-radius: 3px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all .15s; }
    .select-wrap select::-ms-expand {
      display: none; }
    .select-wrap select.active + i {
      transform: scaleY(-1); }
  .select-wrap i {
    position: absolute;
    right: 2px;
    top: 0;
    font-size: 20px;
    line-height: 26px;
    pointer-events: none;
    transition: all 0.15s; }

table {
  border-spacing: 0;
  border-collapse: collapse;
  color: #4C1700; }

td {
  padding: 5px 4px 4px;
  text-align: center;
  border: 1px solid #D5A578; }
  td.xf {
    font-size: 18px;
    color: #F24700; }

.metal:before {
  display: inline-block;
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 10px;
  vertical-align: sub;
  margin: 0 5px 0 -1px; }

.metal.zoloto:before {
  background: linear-gradient(35deg, #E5A300 15%, #F3C762 58%, #EFB30C 87%); }

.metal.serebro:before {
  background: linear-gradient(35deg, #B5B5B5 15%, #D2D2D2 58%, #C0C0C0 87%); }

.metal.alyuminiy:before {
  background: linear-gradient(35deg, #c6c6c6 15%, #dfdfdf 58%, #d0d0d0 87%); }

.metal.platina:before {
  background: linear-gradient(35deg, #c6c6c6 15%, #dfdfdf 58%, #d0d0d0 87%); }

.metal.latun:before {
  background: linear-gradient(35deg, #C98A40 15%, #EEAF65 58%, #D89950 87%); }

.metal.bronza:before {
  background: linear-gradient(35deg, #935D45 15%, #C17E60 58%, #A2654D 87%); }

.metal.tompak:before {
  background: linear-gradient(35deg, #82763d 15%, #c19f60 58%, #927147 87%); }

.metal.stal:before {
  background: linear-gradient(35deg, #D1D1D1 15%, #B5B5B5 45%, #D1D1D1 65%, #C0C0C0 83%); }

.item-prav .metal:before {
  position: absolute;
  left: -6px;
  top: 50%;
  width: 12px;
  height: 12px;
  margin: -6px 0 0; }

.item-znak .metal:before {
  position: absolute;
  left: 15px;
  top: 6px;
  width: 12px;
  height: 12px;
  margin: 0; }

.years-wrap .metal:before {
  width: 16px;
  height: 16px;
  margin: 0 5px 1px -1px; }

.slider-container {
  width: 256px; }

.slider-container .back-bar {
  position: relative;
  height: 4px;
  border-radius: 2px;
  background: #F3EBD5; }

.slider-container .back-bar .selected-bar {
  position: absolute;
  height: 100%;
  background: #D5A578; }

.slider-container .back-bar .pointer {
  position: absolute;
  width: 18px;
  height: 18px;
  top: -7px;
  border-radius: 9px;
  background: #D5A578;
  cursor: pointer;
  z-index: 2; }

.slider-container .back-bar .pointer-label {
  display: none; }

.slider-container .back-bar .pointer.over {
  background: #996036;
  box-shadow: 0 1px 8px rgba(76, 23, 0, 0.2); }

.slider-container .back-bar .pointer.last-active {
  z-index: 3; }

.slider-container .back-bar .focused {
  z-index: 10; }

.slider-container .clickable-dummy {
  cursor: pointer;
  position: absolute;
  top: -2px;
  width: 100%;
  height: 200%;
  z-index: 1; }

.slider-container.slider-readonly .clickable-dummy,
.slider-container.slider-readonly .pointer {
  cursor: auto; }

html {
  height: 100%;
  overflow-x: hidden; }

body {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  font-family: "Istok Web", sans-serif;
  color: #4C1700;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 22px;
  background: url("/catalog/view/theme/custom/img/head-back.png") no-repeat -83px 59px, url("/catalog/view/theme/custom/img/back.png"), #F8F6E8; }
  body.fixed {
    overflow-y: hidden; }

h1 {
  padding: 1px 65px 20px 22px; }
  h1 span {
    font-family: inherit;
    white-space: nowrap; }
  h1 img {
    top: -1px; }

section.center h2 img {
  top: 4px; }

section.center > h2 {
  overflow: hidden;
  padding: 8px 60px 16px 22px; }

section.center > h1 + h2 {
  margin-top: -16px; }

.container {
  display: flex;
  flex-direction: column;
  margin: 0 8px; }
  .container.text {
    display: block;
    padding: 8px 8px; }
    .container.text p {
      font-size: 14px;
      margin: 0 16px 16px; }

#overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(76, 23, 0, 0.15);
  z-index: 900; }

header {
  position: relative;
  padding: 44px 0 0; }
  header .container {
    display: block;
    padding: 22px 0 24px; }
  header:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    height: 44px;
    z-index: 890;
    background: #fff;
    transition: all .15s; }
  header.fixed:before {
    box-shadow: 0 2px 16px rgba(174, 109, 33, 0.25); }
  header .desc {
    display: none; }

#logo {
  display: block;
  position: fixed;
  height: 44px;
  top: 0;
  left: 8px;
  padding: 4px 8px;
  z-index: 891; }
  #logo:hover {
    opacity: .7; }
  #logo img {
    width: 126px;
    height: 35px; }

#contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 8px;
  font-size: 15px;
  /*i{
    position: relative;
    top: 4px;
    font-size: 23px;
    line-height: 21px;
    color: #996036;
  }*/ }
  #contacts div {
    padding: 4px 0;
    white-space: nowrap; }
  #contacts a.messenger {
    display: block;
    float: left;
    position: relative;
    width: 25px;
    text-align: center;
    top: -1px;
    font-size: 23px;
    line-height: 21px;
    color: #996036;
    overflow: hidden;
    /*span{
      display: block;
      position: absolute;
      left: -6px;
      top: -2px;
      opacity: 0;
      font-size: 15px;
      line-height: 30px;
      padding: 0 10px 0 32px;
      border-radius: 3px;
      background: #fff;
      z-index: 1;
      transition: none;
    }*/ }
    #contacts a.messenger:hover {
      color: #F24700;
      /*z-index: 1;
      span{
        color: $color-hover;
        opacity: 1;
        @extend %box-shadow;
        transition: all .15s;
      }*/ }
  #contacts span span {
    font-size: 18px; }

.menu-input {
  display: none; }

.menu-button {
  position: fixed;
  top: 0;
  right: 0;
  width: 58px;
  height: 44px;
  background: #fff;
  cursor: pointer;
  z-index: 920; }
  .menu-button span, .menu-button span:after, .menu-button span:before {
    content: "";
    display: block;
    background: #996036;
    border-radius: 1px;
    width: 21px;
    height: 2px;
    position: relative;
    transition: all .15s ease .1s; }
  .menu-button span {
    margin: 0 auto;
    top: 21px; }
  .menu-button span:before {
    top: -8px; }
  .menu-button span:after {
    top: 6px;
    left: 7px;
    width: 14px; }
  .menu-button:hover span, .menu-button:hover span:after, .menu-button:hover span:before {
    background: #F24700; }
  .menu-input:checked + .menu-button {
    background: transparent;
    transition: all .1s .1s; }
    .menu-input:checked + .menu-button span {
      background-color: transparent; }
    .menu-input:checked + .menu-button span:before {
      top: 0;
      transform: rotate(135deg); }
    .menu-input:checked + .menu-button span:after {
      top: -2px;
      left: 0;
      width: 21px;
      transform: rotate(-135deg); }

nav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -275px;
  width: 275px;
  z-index: 910;
  transition: right .2s ease; }
  nav ul {
    padding: 15px 0 0; }
  nav a {
    display: block;
    font-size: 18px;
    padding: 9px 25px; }
    nav a:hover {
      background: #F8F6E8; }
    nav a#more {
      display: none; }
    nav a.active {
      position: relative;
      color: #996036;
      background: none !important; }
      nav a.active:before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        border-radius: 0 3px 3px 0;
        background: #996036; }
  nav .fav span {
    display: inline-block;
    position: relative;
    top: -9px;
    left: -6px;
    font-size: 13px;
    line-height: 15px;
    padding: 1px 4px 0;
    color: #fff;
    border-radius: 9px;
    background: #F24700; }
  #menu-button:checked ~ nav {
    right: 0; }

#wrap-drop {
  display: none; }

.button-send {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 43px;
  z-index: 1000; }

/*Поле поиска*/
#search {
  position: relative;
  display: flex;
  color: #996036;
  margin: 0 8px 16px;
  transition: all .15s; }
  #search i {
    position: absolute;
    width: 34px;
    font-size: 22px;
    line-height: 32px;
    text-align: right; }
  #search input {
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 36px;
    height: 36px;
    padding: 0 16px 0 42px;
    color: #4c1700;
    background: none; }
    #search input::-webkit-input-placeholder {
      color: #996036; }
    #search input::-ms-input-placeholder {
      color: #996036; }
    #search input::placeholder {
      color: #996036; }

/*Фильтр*/
#filter {
  position: relative;
  margin: 0 8px 16px;
  transition: all .15s; }
  #filter h4 {
    display: flex;
    font-size: 16px;
    line-height: 36px;
    font-weight: normal;
    padding: 0 16px;
    cursor: pointer;
    transition: all .15s; }
    #filter h4 i {
      display: inline-block;
      line-height: 36px;
      vertical-align: middle;
      margin-left: 3px;
      transition: all .15s; }
  #filter.open h4 {
    color: #996036; }
    #filter.open h4 i {
      transform: scaleY(-1); }

#opts {
  display: none;
  border-top: 1px solid #F3EBD5; }
  #filter.open #opts {
    display: block; }
  #opts > div {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 16px; }
    #opts > div:not(:last-of-type) {
      border-bottom: 1px solid #f3ebd5; }
  #opts .sections {
    padding: 24px 12px 16px; }
    #opts .sections input {
      display: none;
      /*&:checked + label:hover{
        color: $color-active;
        background: $color-hover-back;
      }*/ }
      #opts .sections input:checked + label {
        color: #fff;
        background: #996036; }
      #opts .sections input:not(:checked) + label {
        color: #4C1700 !important;
        background: #F3EBD5 !important; }
        #opts .sections input:not(:checked) + label:hover {
          background: #E6CFB0 !important; }
      #opts .sections input + label {
        float: left;
        margin: 0 4px 8px;
        line-height: 32px;
        padding: 0 16px;
        color: #4C1700;
        background: #F3EBD5;
        cursor: pointer;
        transition: all .15s; }
        #opts .sections input + label.over {
          color: #fff;
          background: #996036; }
  #opts .pravs {
    padding: 24px 8px 8px; }
    #opts .pravs .select-wrap {
      float: left;
      margin: 0 8px 16px; }
      #opts .pravs .select-wrap i {
        right: 8px;
        top: 2px; }
    #opts .pravs select {
      font-size: 16px;
      line-height: 32px;
      height: 32px;
      color: #4C1700;
      padding: 0 30px 0 16px; }
  #opts .years {
    padding: 18px 16px; }
    #opts .years > div {
      display: flex;
      height: 32px;
      align-items: center;
      margin: 6px 0; }
      #opts .years > div:nth-child(2) {
        width: 256px; }
    #opts .years .inputs {
      margin-right: 12px; }
      #opts .years .inputs > * {
        margin-right: 8px; }
    #opts .years input {
      width: 59px;
      line-height: 32px;
      height: 32px;
      padding: 0 0 0 12px;
      color: #4C1700;
      background: #F3EBD5;
      transition: all .15s; }
      #opts .years input:focus, #opts .years input.over {
        color: #fff;
        background: #996036; }
  #opts .clear {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 36px;
    color: #D5A578;
    padding: 0 16px;
    transition: all .15s; }
    #opts .clear.active {
      visibility: visible;
      opacity: 1; }
    #opts .clear:hover {
      color: #F24700; }

/*Преимущества*/
.why-wrap {
  display: none; }

/*Сетка Masonry*/
.masonry {
  width: 100% !important; }
  .masonry > div {
    width: 100%;
    float: left;
    padding: 0 8px 16px;
    transition: all .15s; }
    .masonry > div > div {
      width: 100%; }

/*Главная страница. Items */
.item-sect {
  padding: 16px 0 22px !important;
  transition: all .15s; }
  .item-sect.long-text a {
    padding-right: 13px; }
  .item-sect.long-text h3 {
    font-size: 15px; }
  .item-sect h2 {
    color: #F24700; }
  .item-sect a {
    display: flex;
    align-items: center;
    padding: 5px 16px; }
    .item-sect a img {
      flex: 0 0 55px;
      width: 55px;
      height: 55px;
      margin-right: 10px;
      transition: all .15s; }
      .item-sect a img.round {
        border-radius: 28px; }
    .item-sect a.head {
      padding-right: 54px; }
    .item-sect a:not(.head):hover {
      background: #F8F6E8; }
  .item-sect h3 {
    font-size: 16px;
    line-height: 125%;
    font-weight: normal; }
  .item-sect.one {
    padding: 0 !important; }
    .item-sect.one a {
      padding: 21px 16px; }
      .item-sect.one a:before {
        top: 50%; }
      .item-sect.one a:after {
        top: 50%;
        margin-top: -4px; }
    .item-sect.one > img {
      top: 50%;
      margin-top: -26px; }

.item-plus {
  position: relative;
  padding: 21px 60px 21px 16px !important;
  cursor: pointer;
  transition: all .15s; }
  .item-plus > img {
    top: 50%;
    margin-top: -18px; }

.item-articles {
  padding: 16px 0 1px; }
  .item-articles a {
    display: flex;
    flex-direction: column; }
    .item-articles a.head {
      padding-right: 30px; }
    .item-articles a:not(.head) {
      padding: 16px 15px 10px;
      margin: 0 1px; }
      .item-articles a:not(.head):hover {
        background: #F3EBD5; }
    .item-articles a .wrap-img {
      display: block;
      width: 100%;
      padding-top: 52%;
      /* 25:12 Aspect Ratio */
      position: relative;
      margin-bottom: 5px; }
    .item-articles a img {
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0; }
    .item-articles a h3 {
      font-size: 16px;
      line-height: 125%;
      font-weight: normal; }
    .item-articles a:last-child {
      padding-bottom: 25px;
      border-radius: 0 0 2px 2px; }
  .item-articles h2 {
    padding: 5px 16px; }

.wrap-text {
  padding: 16px 0 0;
  margin: 16px 0 20px;
  border-top: 1px solid #E6CFB0; }

/*Форма оценки — сквозная*/
@keyframes shake {
  0% {
    transform: translate(4px, 0); }
  50% {
    transform: translate(-4px, 0); }
  100% {
    transform: translate(0, 0); } }

#form {
  position: relative;
  z-index: 1;
  transition: all .15s, transform .3s; }
  #form.active, #form:hover {
    background: #fff; }
  #form.animate {
    animation: shake .15s 2 linear; }
  #form h2 {
    padding: 21px 60px 12px 16px; }
  #form form {
    padding: 0 8px; }
  #form fieldset {
    width: 100%;
    padding: 0 8px;
    min-inline-size: auto;
    /*div.validetta-error{
      input{
        box-shadow: inset 0 0 0 1px #fa2f3d;
      }
    }*/ }
    #form fieldset:disabled {
      opacity: .7;
      pointer-events: none; }
    #form fieldset.submit {
      margin: 8px 0 0; }
    #form fieldset.files label {
      position: relative;
      width: 100%;
      line-height: 36px;
      color: #996036;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      display: block;
      margin: 8px 0;
      background: #F3EBD5; }
      #form fieldset.files label:hover {
        color: #fff;
        background: #996036; }
      #form fieldset.files label input {
        display: none; }
      #form fieldset.files label .icon-close {
        display: none; }
      #form fieldset.files label.nowrap {
        padding: 0 34px 0 10px; }
        #form fieldset.files label.nowrap .icon-close {
          display: block; }
      #form fieldset.files label.error + small {
        display: block;
        font-size: 14px;
        line-height: 14px;
        color: #f13e4b;
        padding: 0 0 6px 8px; }
    #form fieldset.files .icon-close {
      position: absolute;
      top: 0;
      right: 0;
      height: 36px;
      line-height: 34px;
      font-size: 20px;
      padding: 0 10px;
      cursor: pointer; }
    #form fieldset.msg {
      display: none;
      text-align: center;
      padding: 8px 8px 0; }
      #form fieldset.msg h3 {
        font-family: "Noto Serif Medium";
        font-weight: 500;
        margin: 8px 0 16px; }
      #form fieldset.msg.success h3 {
        color: #13a058; }
      #form fieldset.msg.error h3 {
        color: #13a058; }
      #form fieldset.msg #resend {
        color: #996036;
        background: #F3EBD5; }
        #form fieldset.msg #resend:hover {
          color: #fff;
          background: #996036; }
  #form input {
    width: 100%;
    line-height: 36px;
    height: 36px;
    color: #4C1700;
    padding: 0 10px;
    background: #F3EBD5;
    margin: 8px 0; }
    #form input::-webkit-input-placeholder {
      color: #D5A578; }
    #form input::-ms-input-placeholder {
      color: #D5A578; }
    #form input::placeholder {
      color: #D5A578; }
    #form input.error {
      box-shadow: inset 0 0 0 1px #fa2f3d; }
    #form input + small {
      display: block;
      font-size: 14px;
      line-height: 14px;
      color: #f13e4b;
      padding: 0 0 6px 8px; }
  #form button {
    width: 100%;
    font-size: 16px;
    line-height: 36px;
    color: #996036; }
    #form button[type="submit"] {
      /*&:disabled{
        opacity: .7;
        pointer-events: none;
      }*/ }
      #form button[type="submit"]:hover {
        box-shadow: 0 3px 16px rgba(174, 109, 33, 0.25), 0 1px 4px rgba(174, 109, 33, 0.25); }
  #form .text {
    color: #996036;
    padding: 12px 0 20px; }
    #form .text > div {
      text-align: center;
      line-height: 27px;
      padding: 5px 0; }
  #form .contacts .phone {
    color: #4C1700; }
    #form .contacts .phone span {
      font-size: 18px; }
  #form .contacts a {
    display: inline-block;
    width: 27px;
    text-align: center;
    font-size: 25px;
    line-height: 21px;
    padding: 0;
    color: #996036;
    background: none; }
    #form .contacts a:hover {
      color: #F24700; }
  #form .email {
    padding: 0; }
    #form .email a {
      display: inline-block;
      font-size: 18px;
      padding: 0;
      background: none; }
  #form #ld {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95px;
    height: 36px;
    margin: auto; }
    #form #ld i {
      display: block;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #fff; }
      #form #ld i:nth-child(1) {
        animation: ld 1s ease infinite 0s; }
      #form #ld i:nth-child(2) {
        animation: ld 1s ease infinite 0.1s; }
      #form #ld i:nth-child(3) {
        animation: ld 1s ease infinite 0.2s; }
      #form #ld i:nth-child(4) {
        animation: ld 1s ease infinite 0.3s; }

@keyframes ld {
  0% {
    opacity: 0;
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0); } }

.item-prav {
  float: left;
  overflow: hidden;
  transition: all .15s; }
  .item-prav a.head {
    display: block;
    position: relative;
    overflow: hidden; }
    .item-prav a.head .line {
      bottom: 0; }
    .item-prav a.head h2:before {
      bottom: 11px; }
    .item-prav a.head h2:after {
      bottom: 7px; }
    .item-prav a.head h2.big + span {
      bottom: 70px; }
    .item-prav a.head:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -100%;
      bottom: 0;
      width: 100%;
      background: linear-gradient(90deg, rgba(43, 13, 0, 0) 0, rgba(43, 13, 0, 0.9) 100%);
      z-index: 1;
      transition: all .15s ease; }
    .item-prav a.head:hover .line {
      left: 0; }
    .item-prav a.head:hover:after {
      right: 0; }
  .item-prav .years {
    display: flex;
    flex-wrap: wrap;
    padding: 14px 7px 8px; }
    .item-prav .years a {
      display: block;
      width: 20%;
      line-height: 27px;
      padding: 0 5px;
      text-align: center;
      border-radius: 3px; }
      .item-prav .years a:hover {
        background: #F8F6E8; }
  .item-prav .variety {
    padding: 0 0 8px; }
    .item-prav .variety a {
      display: block;
      position: relative;
      line-height: 21px;
      padding: 3px 16px;
      overflow: hidden; }
      .item-prav .variety a:hover {
        background: #F8F6E8; }
  .item-prav .nominals {
    float: left;
    padding: 0 7px 8px; }
    .item-prav .nominals a {
      display: block;
      float: left;
      line-height: 21px;
      padding: 3px 8px;
      border-radius: 3px; }
      .item-prav .nominals a:hover {
        background: #F8F6E8; }
      .item-prav .nominals a:before {
        display: inline-block;
        content: '';
        position: relative;
        top: -3px;
        width: 4px;
        height: 4px;
        border-radius: 3px;
        margin-right: 6px;
        background: #E6CFB0; }
  .item-prav div:first-of-type {
    margin-top: 16px; }
  .item-prav div:last-of-type {
    margin-bottom: 12px; }

.item-inostr {
  transition: all .15s; }
  .item-inostr a.head {
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 3px; }
    .item-inostr a.head .line {
      bottom: 0; }
    .item-inostr a.head:before {
      bottom: 36px; }
    .item-inostr a.head:after {
      bottom: 32px; }
    .item-inostr a.head:hover .line {
      left: 0; }
  .item-inostr img {
    position: absolute;
    display: block;
    top: -10px;
    left: 14px;
    width: 105px;
    height: 105px; }
  .item-inostr h2 {
    position: relative;
    font-family: "Noto Serif Medium";
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    padding: 108px 50px 24px 16px; }

.item-banknota img {
  top: 24px; }

.item-banknota h2 {
  padding: 144px 50px 24px 16px; }

.item-znak {
  position: relative;
  overflow: hidden;
  padding: 0 0 12px;
  transition: all .15s; }
  .item-znak a.head {
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 3px; }
    .item-znak a.head .line {
      top: 0; }
    .item-znak a.head:before {
      top: 34px; }
    .item-znak a.head:after {
      top: 30px; }
    .item-znak a.head:hover .line {
      left: 0; }
  .item-znak img {
    position: absolute;
    display: block;
    bottom: 16px;
    right: -35px;
    width: 105px;
    height: 105px; }
  .item-znak h2 {
    position: relative;
    font-family: "Noto Serif Medium";
    font-size: 19px;
    line-height: 25px;
    font-weight: 500;
    padding: 22px 50px 12px 16px; }
  .item-znak .variety {
    padding: 0 0 8px; }
    .item-znak .variety a {
      display: block;
      position: relative;
      line-height: 21px;
      padding: 3px 16px;
      overflow: hidden; }
      .item-znak .variety a:hover {
        background: rgba(227, 219, 159, 0.25); }
      .item-znak .variety a.metal {
        padding: 3px 16px 3px 33px; }

.item-product {
  float: left;
  line-height: 125%;
  transition: all .15s; }
  .item-product a.head {
    display: block;
    width: 100%;
    float: left;
    overflow: hidden;
    border-radius: 3px 3px 0 0; }
  .item-product h2 {
    width: 100%;
    float: left;
    font-family: "Noto Serif Medium";
    font-size: 19px;
    line-height: 125%;
    font-weight: 500; }
  .item-product img {
    display: block;
    height: auto;
    max-height: 350px; }
  .item-product .excerpt {
    width: 100%;
    float: left;
    font-size: 14px;
    padding: 10px 16px 12px;
    color: #996036; }
  .item-product .price {
    display: block;
    float: left;
    font-size: 17px;
    padding: 0 16px 20px; }
    .item-product .price.none {
      font-size: 16px;
      color: #996036; }
  .item-product .fav-add {
    right: 8px;
    bottom: 23px;
    color: #D5A578; }
  .item-product.coin a.head {
    padding: 16px 14px 0; }
  .item-product.coin h2 {
    padding: 20px 0 0 2px; }
  .item-product.coin img {
    width: 49.5%;
    float: left; }
    .item-product.coin img + img {
      float: right; }
  .item-product.other a.head {
    padding: 0; }
  .item-product.other h2 {
    padding: 20px 10px 0 16px; }
  .item-product.other img {
    width: auto;
    max-width: 100%;
    margin: 0 auto; }
  .item-product.nominal a.head {
    padding: 5px; }
  .item-product.nominal h2 {
    width: auto;
    float: none;
    margin-right: -5px;
    padding: 8px 48px 8px 11px;
    position: relative; }
    .item-product.nominal h2:before {
      bottom: 19px; }
    .item-product.nominal h2:after {
      bottom: 15px; }
  .item-product.nominal img {
    width: 100%;
    height: auto; }
    .item-product.nominal img + h2 {
      padding-bottom: 16px; }
      .item-product.nominal img + h2:before {
        bottom: 27px; }
      .item-product.nominal img + h2:after {
        bottom: 23px; }

.item-article {
  float: left;
  line-height: 125%;
  transition: all .15s; }
  .item-article a.head {
    display: block;
    width: 100%;
    float: left;
    overflow: hidden;
    border-radius: 3px 3px 0 0; }
  .item-article h2 {
    width: 100%;
    float: left;
    font-family: "Noto Serif Medium";
    font-size: 19px;
    line-height: 125%;
    font-weight: 500;
    padding: 18px 10px 0 16px; }
  .item-article img {
    display: block;
    width: 100%;
    height: auto; }
  .item-article .excerpt {
    width: 100%;
    float: left;
    font-size: 14px;
    padding: 10px 16px 20px;
    color: #996036; }
  .item-article .date {
    float: left;
    font-size: 14px;
    margin: 18px 10px -12px 16px;
    color: #996036; }

.years-wrap {
  width: 100%;
  float: left;
  padding: 0 4px;
  margin: 0 0 8px; }
  .years-wrap a {
    display: block;
    float: left;
    line-height: 21px;
    padding: 6px 16px 5px;
    margin: 0 4px 8px;
    background: #fff;
    border-radius: 3px; }

.cattext-wrap {
  width: 100%;
  float: left;
  padding: 0 8px;
  margin: 0 0 16px; }
  .cattext-wrap .cattext {
    padding: 20px 16px 16px;
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px rgba(183, 105, 33, 0.28); }
  .cattext-wrap p {
    margin: 0 0 8px; }
  .cattext-wrap .cat-photo {
    border-radius: 3px;
    overflow: hidden;
    margin: 0 0 16px; }
    .cattext-wrap .cat-photo img {
      width: 100%;
      height: auto; }

section.left .breadcrumbs {
  margin: 0 19px 16px !important; }

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  color: #D5A578;
  font-size: 14px;
  margin: 0 19px 14px; }
  .breadcrumbs a {
    color: #996036;
    padding: 0 5px; }
    .breadcrumbs a:hover {
      color: #F24700; }
  .breadcrumbs span {
    padding: 0 5px; }

.control {
  display: flex;
  justify-content: space-between;
  color: #996036;
  font-size: 14px;
  padding: 0 8px;
  margin: 0 0 16px; }
  .control.top .pagination {
    display: none; }
  .control.bottom .onpage {
    display: none; }

.list-articles .control.top {
  display: none; }

.pagination {
  display: flex;
  flex: 1 0;
  margin: 0 -1px; }
  .pagination a {
    min-width: 28px;
    line-height: 26px;
    padding: 2px 8px 0;
    margin: 0 1px;
    color: #996036;
    text-align: center;
    border-radius: 3px;
    white-space: nowrap; }
    .pagination a.active {
      background: #F3EBD5; }
    .pagination a:hover {
      background: #F3EBD5;
      color: #F24700; }
    .pagination a i {
      display: inline-block;
      position: relative;
      top: 1px; }
    .pagination a span {
      display: none; }
    .pagination a.prev i {
      transform: rotate(90deg); }
    .pagination a.next i {
      transform: rotate(-90deg); }

.sorting {
  flex: 1;
  box-shadow: inset 0 0 0 1px rgba(183, 105, 33, 0.28); }
  .sorting h4 {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    padding: 0 16px;
    cursor: pointer;
    transition: all .15s; }
    .sorting h4 i {
      line-height: 28px;
      display: inline-block;
      vertical-align: middle;
      margin-right: -3px;
      transition: all .15s; }
  .sorting.open h4 i {
    transform: scaleY(-1); }
  .sorting > div {
    display: none;
    padding: 2px 7px 7px;
    overflow: hidden; }
  .sorting a {
    display: block;
    float: left;
    clear: both;
    color: #996036;
    margin: 1px;
    white-space: nowrap;
    transition: none; }
    .sorting a span {
      display: inline-block;
      line-height: 28px;
      padding: 0 8px;
      border-radius: 3px;
      transition: all .15s; }
    .sorting a i {
      display: none;
      width: 28px;
      font-size: 23px;
      line-height: 28px;
      text-align: center;
      vertical-align: middle;
      margin-left: 2px;
      border-radius: 3px;
      transition: all .15s; }
    .sorting a.active span {
      background: #F3EBD5; }
    .sorting a.active i {
      display: inline-block; }
      .sorting a.active i.active, .sorting a.active i:hover {
        display: inline-block;
        background: #F3EBD5; }
      .sorting a.active i:hover {
        color: #F24700; }
    .sorting a:hover span {
      color: #F24700;
      background: #F3EBD5; }

.onpage {
  display: flex;
  margin-left: 8px; }
  .onpage span {
    line-height: 28px;
    margin-right: 7px;
    white-space: nowrap; }

/* Карточка монеты и т.п. */
.product {
  padding: 0 8px 16px; }
  .product > div {
    float: left;
    position: relative; }
  .product h1 {
    padding: 20px 50px 16px 15px; }
  .product .photos-wrap {
    padding: 0 16px 16px; }
  .product #photos a {
    display: block;
    width: 100%;
    max-width: 350px;
    margin: 0 auto; }
  .product #photos img {
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: transform .3s; }
    .product #photos img.hidden {
      position: absolute;
      left: 50%;
      opacity: 0;
      margin-left: -175px;
      transform: rotateY(90deg); }
    .product #photos img.animate {
      transform: rotateY(90deg); }
  .product #turn {
    display: block;
    margin: 8px auto 0;
    font-size: 14px;
    line-height: 32px;
    padding: 0 16px 0 10px;
    color: #996036;
    transition: all .15s; }
    .product #turn i {
      position: relative;
      top: 4px;
      font-size: 22px; }
    .product #turn:hover {
      color: #F24700; }
  .product #thumbs {
    display: none; }
    .product #thumbs img {
      width: 50%;
      float: left;
      max-width: 175px;
      height: auto;
      padding: 8px;
      border-radius: 3px;
      border: 1px solid #E6CFB0;
      transition: all .15s .15s, opacity .15s; }
      .product #thumbs img.hidden {
        opacity: .85;
        border-color: transparent;
        cursor: pointer; }
      .product #thumbs img:hover {
        opacity: 1; }
  .product .desc {
    padding: 0 16px 8px;
    transition: all .3s; }
    .product .desc.hidden {
      height: 450px;
      overflow: hidden; }
      .product .desc.hidden:after {
        content: '';
        height: 160px;
        left: 0;
        right: 0;
        position: absolute;
        bottom: 0;
        background: linear-gradient(0, #fff 30%, rgba(255, 255, 255, 0) 100%);
        pointer-events: none;
        z-index: 1;
        transition: all .3s ease; }
    .product .desc:not(.hidden) #more {
      display: none; }
    .product .desc .prices {
      margin: 0 0 8px; }
      .product .desc .prices .price {
        color: #F24700;
        margin: 0 0 16px -1px; }
        .product .desc .prices .price.none {
          font-size: 19px;
          color: #996036; }
      .product .desc .prices p {
        margin: 0 0 16px; }
      .product .desc .prices a {
        display: inline-block;
        position: relative;
        font-size: 14px;
        padding: 8px;
        margin-left: -8px;
        color: #F24700; }
        .product .desc .prices a:after {
          display: inline-block;
          content: "\e907";
          font-family: Icons;
          line-height: 20px;
          vertical-align: middle;
          transform: rotate(-90deg);
          margin-left: -3px;
          opacity: 0;
          transition: opacity,margin-left .15s; }
        .product .desc .prices a:hover {
          color: #F24700;
          text-decoration: underline; }
          .product .desc .prices a:hover:after {
            opacity: 1;
            margin-left: 3px; }
    .product .desc .specs .metal {
      margin: 0 0 16px; }
    .product .desc .specs ul {
      margin: 0 0 16px; }
    .product .desc .specs li {
      margin: 0 0 5px; }
    .product .desc .specs h3 {
      font-size: 16px;
      margin: 0 0 8px; }
    .product .desc .sides {
      /*.side{
        margin: 0 0 16px;
      }*/ }
      .product .desc .sides h2 {
        font-size: 19px;
        margin: 16px 0 8px; }
      .product .desc .sides p {
        margin: 0 0 8px; }
  .product table {
    width: 100%;
    margin: 1px 1px 0 0; }
    .product table tbody {
      display: flex;
      width: 100%;
      justify-content: stretch; }
    .product table tr {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column; }
      .product table tr.safe {
        color: #996036; }
    .product table td {
      margin: 0 0 -1px -1px; }
  .product .fav-add {
    top: 11px;
    right: 4px;
    color: #996036; }
    .product .fav-add span {
      display: none; }

.share-wrap {
  width: 100%;
  float: left;
  padding: 0 8px 5px; }

.share {
  padding: 16px; }
  .share > span {
    display: block;
    color: #996036;
    margin-bottom: 6px; }

.form-wrap {
  width: 100%;
  float: left;
  margin: 0 0 16px;
  padding: 0 8px; }

.pluses-wrap {
  width: 100%;
  float: left; }
  .pluses-wrap > div {
    padding: 0 8px;
    margin: 0 0 16px; }

.related {
  width: 100%;
  float: left; }
  .related a {
    display: block;
    font-size: 18px;
    padding: 16px 55px 16px 16px;
    transition: all .15s; }
    .related a:before {
      top: 50%; }
    .related a:after {
      top: 50%;
      margin-top: -4px; }

.similar-wrap {
  width: 100%;
  float: left; }

.viewed-wrap {
  width: 100%;
  float: left; }

.about-wrap {
  width: 100%;
  float: left;
  padding: 0 8px;
  margin: 0 0 16px; }

.about-photo {
  position: relative;
  overflow: hidden;
  border-radius: 3px 3px 0 0; }
  .about-photo.no-gr:before {
    display: none; }

.about-text {
  position: relative;
  padding: 20px 16px 52px;
  margin: -1px 0 0;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px rgba(183, 105, 33, 0.28); }
  .about-text.small {
    padding-bottom: 16px; }
  .about-text.hidden {
    height: 242px;
    padding: 20px 16px 16px; }
    .about-text.hidden:after {
      content: "";
      height: 160px;
      left: 1px;
      right: 1px;
      position: absolute;
      bottom: 0;
      border-radius: 2px;
      background: linear-gradient(to top, #f8f6e8 29%, rgba(248, 246, 232, 0) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      pointer-events: none;
      z-index: 1;
      border-bottom: 1px solid #e6cfb0;
      transition: all .3s ease; }
  .about-text #more {
    font-size: 14px; }
    .about-text #more.open i {
      transform: scaleY(-1); }
  .about-text p {
    font-size: 14px;
    line-height: 140%;
    margin: 0 0 4px; }

article {
  margin: 0 8px 16px;
  padding: 0 0 12px; }
  article .share {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 16px 8px; }
    article .share > span {
      float: left;
      line-height: 24px;
      margin: 4px 0; }
    article .share .ya-share2 {
      float: left;
      margin: 4px 16px 4px 8px; }
  article h1 {
    padding: 20px 15px 8px; }
  article h2 {
    font-family: "Noto Serif Medium";
    font-size: 24px;
    line-height: 27px;
    font-weight: 500;
    padding: 8px 15px 16px; }
  article h3 {
    font-family: "Noto Serif Medium";
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    padding: 4px 15px 14px; }
  article p {
    padding: 0 16px 16px; }
    article p > img {
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;
      margin: 8px auto 4px; }
  article > ul {
    margin: -6px 32px 16px; }
    article > ul li {
      margin: 0 0 4px; }
      article > ul li:before {
        content: "\2981";
        /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: #996036;
        /* Change the color */
        display: inline-block;
        /* Needed to add space between the bullet and the text */
        width: 1em;
        /* Also needed for space (tweak if needed) */
        margin-left: -1em;
        /* Also needed for space (tweak if needed) */ }
  article a {
    color: #F24700; }
    article a:hover {
      text-decoration: underline; }
  article blockquote {
    position: relative;
    font-style: italic; }
    article blockquote p {
      padding: 0 32px 16px; }
    article blockquote:before, article blockquote:after {
      display: block;
      position: absolute;
      font-family: "Noto Serif Medium";
      color: #E6CFB0;
      font-size: 32px; }
    article blockquote:before {
      content: '«';
      left: 8px;
      top: -4px; }
    article blockquote:after {
      content: '»';
      right: 14px;
      bottom: 20px; }
  article figure {
    width: 100%;
    float: left;
    margin: 8px 0 4px;
    /*&.head{
      img{
        border-radius: 3px 3px 0 0;
      }
    }*/ }
    article figure img {
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;
      margin: 0 auto; }
    article figure:first-of-type {
      margin-bottom: 16px; }
    article figure.padding img {
      padding: 0 16px; }
    article figure.cols-2 figcaption, article figure.cols-3 figcaption {
      padding: 12px 16px 10px; }
      article figure.cols-2 figcaption > div, article figure.cols-3 figcaption > div {
        margin: 0 0 6px; }
  article figcaption {
    width: 100%;
    float: left;
    font-size: 14px;
    line-height: 125%;
    color: #996036;
    padding: 12px 16px 16px; }
  article .table-responsive {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    article .table-responsive > table {
      margin: 0 16px 20px; }
      article .table-responsive > table.left td {
        text-align: left; }
      article .table-responsive > table.no-border td {
        border-color: transparent; }
  article > table {
    width: auto;
    margin: 0 16px 20px; }
    article > table.left td {
      text-align: left; }
    article > table.no-border td {
      border-color: transparent; }

footer {
  margin-top: auto;
  border-top: 1px solid #E6CFB0; }
  footer .container {
    padding: 30px 8px 80px; }
  footer ul {
    margin-bottom: 16px; }
    footer ul a {
      display: inline-block;
      line-height: 27px;
      padding: 0 16px; }
  footer p {
    font-size: 14px;
    color: #996036;
    margin: 0 16px; }
    footer p.mail {
      margin-top: 16px; }

@media screen and (min-width: 360px) {
  .onpage {
    margin-left: 15px; } }

@media screen and (min-width: 415px) {
  .product table {
    min-width: 350px;
    width: auto; } }

@media screen and (min-width: 640px) {
  body {
    background-position: 100px 59px; }
  .container.text {
    column-count: 2; }
  .menu-button {
    display: none; }
  nav {
    width: 100%;
    right: 0;
    bottom: auto; }
    nav ul.menu {
      display: flex;
      justify-content: space-between;
      padding: 0 22px; }
      nav ul.menu li.more {
        display: none; }
      nav ul.menu a {
        font-size: 16px;
        padding: 5px 8px;
        margin: 5px 2px;
        white-space: nowrap;
        border-radius: 3px; }
        nav ul.menu a.active:before {
          left: 0;
          top: -5px;
          right: 0;
          bottom: auto;
          width: auto;
          height: 3px;
          border-radius: 0 0 3px 3px; }
    nav a#more-nav {
      display: block;
      width: 58px;
      position: relative;
      padding: 5px 18px 5px 8px;
      margin-right: 5px; }
      nav a#more-nav.opened {
        background: #f8f6e8; }
      nav a#more-nav .icon-more {
        font-size: 22px;
        line-height: 0;
        position: relative;
        top: 4px;
        left: -1px; }
  #wrap-drop {
    position: absolute;
    width: 307px;
    top: 42px;
    right: 0;
    padding: 0 16px 16px;
    z-index: 920;
    overflow-y: hidden; }
  #more-drop {
    padding: 10px 0;
    background: #fff;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 2px 16px rgba(174, 109, 33, 0.25); }
    #more-drop a {
      display: block;
      font-size: 16px;
      padding: 7px 25px;
      margin: 0; }
  header {
    width: 100%; }
    header:before {
      height: 42px; }
    header .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
  #logo {
    height: 49px;
    left: 16px;
    position: relative;
    background: url(/catalog/view/theme/custom/img/logo-back.svg) no-repeat 8px 4px;
    background-size: 148px 41px; }
    #logo:hover {
      transform: scale(1.05);
      opacity: 1; }
    #logo img {
      width: 148px;
      height: 41px; }
  #contacts {
    padding: 0 24px 0; }
  /*Сетка Masonry*/
  .masonry > div {
    width: 50%; }
  .control {
    padding: 0 16px; }
    .control.top {
      margin: -8px 0 16px; }
  .sorting {
    box-shadow: none; }
    .sorting h4 {
      display: none; }
    .sorting > div {
      display: block !important;
      padding: 0;
      margin: 0 -1px; }
    .sorting a {
      clear: none;
      margin: 0 8px 0 1px; }
  .pagination a span {
    display: inline; }
  .pagination a.prev {
    padding: 2px 11px 0 9px; }
    .pagination a.prev i {
      left: -3px; }
  .pagination a.next {
    padding: 2px 9px 0 11px; }
    .pagination a.next i {
      right: -3px; }
  article figure.cols-2 figcaption, article figure.cols-3 figcaption {
    padding: 12px 8px 16px; }
    article figure.cols-2 figcaption > div, article figure.cols-3 figcaption > div {
      float: left;
      padding: 0 8px;
      margin: 0; }
      article figure.cols-2 figcaption > div:before, article figure.cols-3 figcaption > div:before {
        display: none; }
  article figure.cols-2 figcaption > div {
    width: 50%; }
  article figure.cols-3 figcaption > div {
    width: 33.33%; } }

@media screen and (min-width: 640px) and (max-width: 767px) {
  .form-wrap {
    width: 50%;
    float: left; }
  .pluses-wrap {
    width: 50%;
    float: left; } }

@media screen and (min-width: 640px) and (max-width: 1339px) {
  .about-wrap {
    padding: 0; }
  .about-photo-wrap {
    width: 50%;
    float: left;
    padding: 0 8px; }
  .about-text-wrap {
    width: 50%;
    float: left;
    padding: 0 8px; }
  .about-photo {
    height: 260px;
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px rgba(183, 105, 33, 0.28); }
    .about-photo:before {
      display: none; }
    .about-photo h2 {
      position: static;
      color: #4C1700;
      padding: 16px 16px 6px; }
    .about-photo span {
      position: static;
      color: #996036;
      padding: 0 16px;
      left: auto;
      bottom: auto; }
  .about-text {
    margin: 0;
    border-radius: 3px; }
    .about-text.hidden {
      height: 260px; } }

@media screen and (min-width: 768px) {
  body {
    background-position: 86px 68px; }
  .container.text {
    column-count: 3; }
  nav ul.menu {
    margin-right: 245px; }
  #wrap-drop {
    right: 55px; }
  .button-send {
    width: 234px;
    bottom: auto;
    top: 5px;
    left: auto;
    right: 16px;
    font-size: 16px;
    line-height: 32px;
    border-radius: 3px; }
  header .desc {
    display: block;
    width: 227px;
    font-size: 15px;
    padding: 0 8px 0 11px;
    border-left: 1px solid #996036; }
    header .desc h4 {
      font-size: 15px;
      font-weight: normal;
      color: #996036; }
    header .desc p {
      line-height: 125%; }
  #contacts {
    margin-left: auto;
    padding: 0 12px; }
  .masonry > div {
    width: 33.33%; }
  aside.left {
    display: flex;
    justify-content: stretch; }
    aside.left .search-wrap {
      order: 2;
      width: 66.67%;
      transition: all .2s; }
      aside.left .search-wrap.disable {
        width: 62px; }
    aside.left .filter-wrap {
      flex: 1 1;
      order: 1;
      width: 33.33%;
      transition: all .2s; }
  #filter h4 {
    justify-content: space-between; }
    #filter h4 i {
      margin: 0 -2px 0 auto; }
  #opts > div {
    flex-wrap: nowrap; }
  #opts .clear {
    top: auto;
    bottom: 2px; }
  #filter.filter-articles #opts > div {
    flex-wrap: wrap;
    padding-right: 96px; }
  .list-articles .control.top {
    display: flex; }
    .list-articles .control.top .pagination {
      display: flex;
      margin-right: auto; }
    .list-articles .control.top .onpage {
      margin-left: 8px; }
  .product #more {
    display: none; }
  .product .fav-add {
    padding: 0 12px 12px; }
  .product h1 {
    float: right;
    width: 66.66%;
    padding: 20px 20px 12px 20px; }
  .product .photos-wrap {
    float: left;
    width: 33.34%;
    padding: 20px 0 24px 16px; }
    .product .photos-wrap .photos {
      margin-right: -4px; }
  .product #turn {
    display: none; }
  .product #thumbs {
    display: block;
    padding: 16px; }
  .product .desc {
    float: right;
    width: 66.66%;
    padding-left: 22px; }
    .product .desc.hidden {
      height: auto;
      overflow: visible; }
    .product .desc:after {
      display: none; }
  .product table {
    width: 100%; }
    .product table tbody {
      display: table; }
    .product table tr {
      display: table-row; }
    .product table td {
      margin: 0; }
  .product .fav-add {
    position: static;
    float: left;
    padding-left: 18px; }
    .product .fav-add span {
      display: inline;
      font-size: 14px;
      line-height: 24px;
      vertical-align: bottom;
      margin-left: 5px; }
  article .share {
    padding: 12px 42px 16px;
    max-width: 784px;
    margin: 0 auto;
    box-shadow: none; }
  article h1 {
    padding: 20px 41px 8px;
    max-width: 786px;
    margin: 0 auto; }
  article h2 {
    padding: 8px 41px 16px;
    max-width: 786px;
    margin: 0 auto; }
  article h3 {
    padding: 4px 41px 14px;
    max-width: 786px;
    margin: 0 auto; }
  article p {
    padding: 0 42px 16px;
    max-width: 784px;
    margin: 0 auto; }
  article > ul {
    max-width: 784px;
    margin: -6px auto 16px;
    padding: 0 58px 0; }
  article blockquote {
    max-width: 784px;
    margin: 0 auto; }
    article blockquote p {
      padding: 0 80px 16px; }
    article blockquote:before, article blockquote:after {
      font-size: 40px; }
    article blockquote:before {
      left: 38px;
      top: -4px; }
    article blockquote:after {
      right: 60px;
      bottom: 6px; }
  article figcaption {
    padding: 12px 42px 16px;
    text-align: center; }
  article figure:first-of-type {
    margin-bottom: 24px; }
  article .table-responsive > table {
    max-width: 97%;
    margin: 0 auto 20px; }
    article .table-responsive > table.p {
      max-width: 700px; }
  article > table {
    max-width: 784px;
    margin: 0 auto 20px; }
  .cattext-wrap .cat-photo {
    max-width: 50%;
    float: left;
    margin-right: 16px; }
  footer .container {
    display: flex;
    flex-direction: row;
    padding: 40px 8px 45px; }
    footer .container > div {
      width: 66.67%;
      float: left; }
  footer ul {
    width: 33.33%;
    float: left; }
  footer p {
    margin: 0 20px; } }

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .form-wrap {
    width: 100%; }
    .form-wrap #form fieldset {
      width: 33.3%; }
      .form-wrap #form fieldset.files {
        order: 4; }
      .form-wrap #form fieldset.submit {
        order: 3;
        padding: 8px; }
      .form-wrap #form fieldset.msg {
        order: 5;
        width: 66.67%; }
      .form-wrap #form fieldset.text {
        width: 100%;
        order: 6;
        padding: 0 8px 12px; }
        .form-wrap #form fieldset.text > div {
          display: block;
          float: left;
          margin-right: 16px; }
        .form-wrap #form fieldset.text .contacts > div {
          float: left; }
        .form-wrap #form fieldset.text .contacts .phone {
          margin-right: 10px; }
    .form-wrap form {
      display: flex;
      flex-wrap: wrap; }
  .pluses-wrap {
    width: 100%; }
    .pluses-wrap > div {
      width: 33.33%;
      float: left; } }

@media screen and (min-width: 768px) and (max-width: 1339px) {
  .about-photo-wrap {
    width: 33.33%; }
  .about-text-wrap {
    width: 66.67%; }
  #form fieldset.submit {
    margin: 0; } }

@media screen and (min-width: 992px) {
  body {
    background-position: 116px 54px; }
  .masonry > div {
    width: 33.33%; }
  .container.text {
    column-count: 4; }
  .control.top .pagination {
    display: flex;
    margin-right: auto; }
  .control.top .sorting {
    flex: 0 1 auto; }
  .sorting {
    flex: 0 1 auto; }
  .product .desc .specs {
    width: 100%;
    float: left; }
    .product .desc .specs h3 {
      width: 50%;
      float: left;
      margin-top: -38px; }
    .product .desc .specs ul {
      width: 50%;
      float: left;
      padding-right: 16px; }
  footer .container > div {
    width: 75%; }
  footer ul {
    width: 25%; } }

@media screen and (min-width: 1200px) {
  body {
    background-position: right 7.6% top 54px; }
  .masonry {
    width: 75% !important;
    float: right; }
    .masonry > div {
      width: 33.33%; }
  .container-main {
    flex-direction: row;
    padding: 0; }
  .container.cols-three {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end; }
    .container.cols-three section.center {
      width: 75%;
      display: flex;
      flex-direction: column; }
      .container.cols-three section.center .masonry {
        width: 100% !important; }
    .container.cols-three aside.right {
      width: 75%; }
  aside.left.horizontal .search-wrap {
    width: 75%; }
    aside.left.horizontal .search-wrap.disable {
      width: 266px; }
  aside.left.horizontal .filter-wrap {
    width: 25%; }
  aside.left.horizontal #opts {
    padding: 12px 0; }
  aside.left.vertical {
    width: 25%;
    float: left;
    flex-direction: column; }
    aside.left.vertical .search-wrap {
      width: 100%;
      order: 1; }
      aside.left.vertical .search-wrap.disable {
        width: 100%; }
    aside.left.vertical .filter-wrap {
      flex: 0 1;
      width: 100%;
      order: 2; }
    aside.left.vertical .why-wrap {
      order: 3; }
    aside.left.vertical #filter h4 {
      font-family: "Noto Serif Medium";
      font-weight: 500;
      font-size: 20px;
      line-height: 35px;
      text-transform: capitalize;
      color: #4C1700 !important;
      padding: 16px 16px 0;
      cursor: auto; }
      aside.left.vertical #filter h4:before {
        display: none; }
      aside.left.vertical #filter h4 i {
        display: none; }
    aside.left.vertical #opts {
      display: block !important;
      padding: 0 0 16px;
      border: none; }
      aside.left.vertical #opts > div {
        flex-wrap: wrap; }
      aside.left.vertical #opts .pravs .select-wrap {
        width: 100%; }
      aside.left.vertical #opts .pravs select {
        width: 100%; }
      aside.left.vertical #opts .years > div:nth-child(2) {
        width: 100%; }
      aside.left.vertical #opts .clear {
        top: 18px;
        left: 91px;
        right: auto;
        bottom: auto; }
    aside.left.vertical #filter.filter-articles #opts > div {
      padding-right: 12px; }
    aside.left.vertical #filter.filter-articles #opts .clear {
      right: 0;
      left: auto; }
  aside.left #opts {
    border-color: #E6CFB0; }
    aside.left #opts > div {
      padding: 12px 16px;
      border: none !important; }
    aside.left #opts .sections {
      padding: 12px 12px 4px; }
    aside.left #opts .pravs {
      padding: 12px 8px 0;
      margin-bottom: -4px; }
    aside.left #opts .years {
      padding: 6px 16px; }
  #search {
    background: none;
    box-shadow: inset 0 0 0 1px rgba(183, 105, 33, 0.28) !important; }
  #filter {
    background: none !important;
    box-shadow: inset 0 0 0 1px rgba(183, 105, 33, 0.28) !important; }
  /*Почему мы?*/
  .why-wrap {
    display: block;
    width: 100%;
    float: left; }
  .why {
    padding: 16px 16px 20px;
    margin: 0 8px; }
    .why h4 {
      font-family: "Noto Serif Medium";
      font-weight: 500;
      font-size: 20px;
      line-height: 35px; }
    .why li {
      padding: 2px 0; }
  .button-send {
    position: static;
    width: 250px;
    font-size: 18px;
    line-height: 50px;
    margin-right: 8px;
    z-index: auto; }
    .button-send:hover {
      transform: translateY(-2px);
      box-shadow: 0 3px 16px rgba(174, 109, 33, 0.25), 0 1px 4px rgba(174, 109, 33, 0.25); }
  header .desc {
    margin-right: 150px; }
  nav ul.menu {
    margin: 0 auto; }
    nav ul.menu li.more {
      display: block; }
    nav ul.menu a {
      padding: 5px 16px; }
  nav .fav {
    position: relative; }
    nav .fav span {
      position: absolute;
      top: -1px;
      right: 0;
      left: auto; }
  nav a#more-nav {
    display: none; }
  #wrap-drop {
    display: none !important; }
  .breadcrumbs {
    margin: 7px 19px 14px; }
  /* Карточка монеты и т.п. */
  section.left .sidebar {
    float: right;
    width: 25%; }
  .product {
    float: left;
    width: 75%; }
  .share-wrap {
    padding: 0 8px 16px; }
  .share {
    box-shadow: inset 0 0 0 1px rgba(183, 105, 33, 0.28); }
  .related {
    width: 75%; }
    .related .masonry {
      width: 100% !important; }
  .similar-wrap {
    width: 75%; }
    .similar-wrap .masonry {
      width: 100% !important; }
  .viewed-wrap {
    width: 75%; }
    .viewed-wrap .masonry {
      width: 100% !important; }
  article {
    margin: 0 8px 16px; } }

@media screen and (min-width: 1200px) and (max-width: 1339px) {
  .container.cols-three aside.right .form-wrap {
    width: 100%; }
    .container.cols-three aside.right .form-wrap #form fieldset {
      width: 33.3%; }
      .container.cols-three aside.right .form-wrap #form fieldset.files {
        order: 4; }
      .container.cols-three aside.right .form-wrap #form fieldset.submit {
        order: 3;
        padding: 8px; }
      .container.cols-three aside.right .form-wrap #form fieldset.msg {
        order: 5;
        width: 66.67%; }
      .container.cols-three aside.right .form-wrap #form fieldset.text {
        width: 100%;
        order: 6;
        padding: 0 8px 12px; }
        .container.cols-three aside.right .form-wrap #form fieldset.text > div {
          display: block;
          float: left;
          margin-right: 16px; }
        .container.cols-three aside.right .form-wrap #form fieldset.text .contacts > div {
          float: left; }
        .container.cols-three aside.right .form-wrap #form fieldset.text .contacts .phone {
          margin-right: 10px; }
    .container.cols-three aside.right .form-wrap form {
      display: flex;
      flex-wrap: wrap; }
  .container.cols-three aside.right .pluses-wrap {
    width: 100%; }
    .container.cols-three aside.right .pluses-wrap > div {
      width: 33.33%;
      float: left; } }

@media screen and (min-width: 1340px) {
  body {
    background-position: right 65px top 54px; }
  .masonry.cols-3 > div {
    width: 33.33%; }
  .masonry.cols-4 {
    width: 80% !important; }
    .masonry.cols-4 > div {
      width: 25%; }
  aside.left.horizontal .search-wrap {
    width: 80%; }
  aside.left.horizontal .filter-wrap {
    width: 20%; }
  aside.left.vertical {
    width: 20%; }
  section.left .sidebar {
    width: 20%; }
  .container {
    width: 1330px;
    margin: 0 auto;
    /*&-main{
      display: block;
      //flex-direction: row;
      padding: 17px 0 0;
      overflow: hidden;
    }*/ }
    .container.cols-three {
      flex-wrap: nowrap; }
      .container.cols-three section.center {
        width: 60%; }
      .container.cols-three aside.right {
        width: 20%; }
      .container.cols-three.list-coins h1 {
        margin-right: -33.34%; }
      .container.cols-three.list-coins .breadcrumbs {
        margin-right: -33.34%; }
      .container.cols-three.list-coins .control {
        margin-right: -33.34%; }
        .container.cols-three.list-coins .control.bottom {
          margin-right: auto; }
      .container.cols-three.list-coins aside.right {
        margin-top: 125px; }
      .container.cols-three.list-articles h1 {
        margin-right: -33.34%; }
      .container.cols-three.list-articles .control {
        margin-right: -33.34%; }
        .container.cols-three.list-articles .control.bottom {
          margin-right: auto; }
      .container.cols-three.list-articles aside.right {
        margin-top: 61px; }
  header .container {
    height: 119px;
    padding: 26px 0 28px; }
  header .desc {
    display: flex;
    align-items: center;
    width: auto;
    margin-right: 140px;
    border: none; }
    header .desc h4 {
      width: 130px;
      font-size: 16px;
      padding: 0 11px;
      text-align: right; }
    header .desc p {
      width: 227px;
      padding: 0 8px 0 11px;
      border-left: 1px solid #996036; }
  nav ul.menu {
    width: 1318px;
    padding: 0; }
  .container.text {
    padding-left: 271px; }
  .product {
    width: 80%; }
    .product > div {
      float: left;
      position: relative; }
      .product > div:before {
        content: '';
        position: absolute;
        width: 1px;
        top: 0;
        bottom: 0;
        left: 40%;
        background: #F3EBD5; }
    .product h1 {
      width: 60%; }
    .product .photos-wrap {
      width: 40%; }
      .product .photos-wrap .photos {
        margin-right: 14px; }
    .product #thumbs {
      padding: 16px 24px; }
    .product .desc {
      width: 60%; }
  .related {
    width: 80%; }
    .related .masonry.cols-4 {
      width: 100% !important; }
  .similar-wrap {
    width: 80%; }
    .similar-wrap .masonry.cols-4 {
      width: 100% !important; }
  .viewed-wrap {
    width: 80%; }
    .viewed-wrap .masonry.cols-4 {
      width: 100% !important; }
  footer .container {
    padding: 40px 271px 45px; }
    footer .container > div {
      width: 66.67%; }
  footer ul {
    width: 33.33%; } }

@media screen and (min-width: 1410px) {
  body {
    background-position: right 11.3% top 54px; } }

@media screen and (min-width: 1506px) {
  body {
    background-position: right 14.6% top 54px; } }

@media screen and (min-width: 1570px) {
  body {
    background-position: right 16.6% top 54px; } }

@media screen and (min-width: 1890px) {
  body {
    background-position: right 23.7% top 54px; } }
