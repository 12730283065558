$family-stack: 'Istok Web', sans-serif;
$family-head: 'Noto Serif Medium';
$color-primary: #4C1700;
$color-active: #996036;
$color-active-filter: #D5A578;
//$color-border: #EDCFAB;
$color-border: #E6CFB0;
$color-hover-back: #F3EBD5;
$color-hover-back-2: #F8F6E8;
$color-hover: #F24700;

%back-button-gradient {
  display: block;
  font-size: 18px;
  text-align: center;
}
%box-shadow{
  box-shadow: 0 4px 16px rgba(174, 109, 33, 0.2);
}
@mixin border{
  //box-shadow: inset 0 0 0 1px $color-border;
  box-shadow: inset 0 0 0 1px rgba(183, 105, 33, 0.28)
}
@mixin border-important{
  //box-shadow: inset 0 0 0 1px $color-border!important;
  box-shadow: inset 0 0 0 1px rgba(183, 105, 33, 0.28)!important
}
%img-sect{
  position: absolute;
  top: 16px;
  right: 21px;
  width: 36px;
  height: 36px;
  transition: all .15s;
}
%title-sect{
  font-family: $family-head;
  font-weight: 500;
  font-size: 20px;
  line-height: 125%;
  > span{
    font-family: $family-head;
    white-space: nowrap;
  }
}
%line-before{
  content: "";
  position: absolute;
  right: 47px;
  height: 1px;
  width: 5px;
  background: $color-hover;
  opacity: 0;
  z-index: 1;
  transition: all .25s;
}
%line-before-hover{
  width: 22px;
  right: 19px;
  opacity: 1;
}
%line-after{
  content: "";
  position: absolute;
  right: 47px;
  height: 9px;
  width: 9px;
  border-top: 1px solid $color-hover;
  border-right: 1px solid $color-hover;
  transform: rotate(45deg);
  opacity: 0;
  transition: all .25s;
}
%line-after-hover{
  right: 19px;
  opacity: 1;
}
%a-head{
  position: relative;
  z-index: 1;
  + img + a{
    margin-top: 10px;
  }
  &:before{
    @extend %line-before;
    top: 17px;
  }
  &:hover:before{
    @extend %line-before-hover;
  }
  &:after{
    @extend %line-after;
    top: 13px;
  }
  &:hover:after{
    @extend %line-after-hover;
  }
  &:hover + img{
    right: 13px;
    opacity: 0;
  }
}
%fav-add{
  position: absolute;
  font-size: 26px;
  padding: 12px;
  cursor: pointer;
  transition: all .15s;
  &.active{
    color: $color-hover;
    &:before{
      content: "\e90d";
    }
  }
  &:hover{
    color: $color-hover;
  }
}
%line-hover{
  display: block;
  position: absolute;
  left: -100%;
  height: 2px;
  width: 100%;
  background: $color-hover;
  z-index: 2;
  transition: all .15s ease-out;
}
%head-prav{
  img{
    display: block;
    width: 100%;
    height: auto;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(90deg, rgba(43, 13, 0, 0.6) 0, rgba(43, 13, 0, 0) 100%);
    z-index: 1;
  }
  span{
    position: absolute;
    left: 16px;
    bottom: 44px;
    color: #fff;
    opacity: 0.5;
    z-index: 2;
  }
  h2{
    position: absolute;
    left: 16px;
    right: 0;
    bottom: 16px;
    color: #fff;
    font-family: $family-head;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    padding-right: 50px;
    z-index: 2;
  }
}
%more{
  position: absolute;
  left: 0;
  bottom: 6px;
  padding: 16px;
  z-index: 2;
  cursor: pointer;
  transition: all .15s;
  i{
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 2px;
  }
  &:hover{
    color: $color-hover;
  }
}
@mixin form-wrap{
  width: 100%;
  #form{
    fieldset{
      width: 33.3%;
      &.files{
        order: 4;
      }
      &.submit{
        order: 3;
        padding: 8px;
      }
      &.msg{
        order: 5;
        width: 66.67%;
      }
      &.text{
        width: 100%;
        order: 6;
        padding: 0 8px 12px;
        > div{
          display: block;
          float: left;
          margin-right: 16px;
        }
        .contacts{
          >div{
            float: left;
          }
          .phone{
            margin-right: 10px;
          }
        }
      }
    }
  }
  form{
    display: flex;
    flex-wrap: wrap;
  }
}
@mixin pluses-wrap{
  width: 100%;
  > div{
    width: 33.33%;
    float: left;
  }
}

@import url('https://fonts.googleapis.com/css?family=Istok+Web:400,400i,700&display=swap');
@font-face {
  font-family: 'Noto Serif Medium';
  //src: url('/fonts/notoserif-medium-web.woff2') format('woff2'),
  //url('/fonts/notoserif-medium-web.woff') format('woff');
  src: url('/catalog/view/theme/custom/fonts/notoserif-medium.woff2') format('woff2'), //использовался шрифт от Adobe
  url('/catalog/view/theme/custom/fonts/notoserif-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Icons';
  src:
          url('/catalog/view/theme/custom/fonts/Icons.ttf?7wl8vw') format('truetype'),
          url('/catalog/view/theme/custom/fonts/Icons.woff?7wl8vw') format('woff'),
          url('/catalog/view/theme/custom/fonts/Icons.svg?7wl8vw#Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icon-turn:before {
  content: "\e90e";
}
.icon-fav-active:before {
  content: "\e90d";
}
.icon-sort-aya:before {
  content: "\e908";
}
.icon-sort-yaa:before {
  content: "\e909";
}
.icon-sort-19:before {
  content: "\e90a";
}
.icon-sort-91:before {
  content: "\e90b";
}
.icon-select:before {
  content: "\e90c";
}
.icon-close:before {
  content: "\e904";
}
.icon-fav:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e906";
}
.icon-arrow:before {
  content: "\e907";
}
.icon-more:before {
  content: "\e903";
}
.icon-telegram:before {
  content: "\e900";
}
.icon-viber:before {
  content: "\e901";
}
.icon-whatsapp:before {
  content: "\e902";
}
*{
  font-family: $family-stack;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  background: none;
}
button {
  font-size: 16px;
  transition: all .15s ease;
  cursor: pointer;
}
input {
  font-size: 16px;
}
i[class*=" icon-"], i[class^=icon-] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul {
  list-style: none;
}
a{
  color: $color-primary;
  text-decoration: none;
  transition: all .15s;
  &:hover{
    color: $color-hover;
  }
}
h1, h2{
  font-family: $family-head;
  line-height: 115%;
  font-weight: 500;
  position: relative;
}
h1{
  font-size: 28px;
}
h2{
  font-size: 24px;
}
%h2{
  font-family: $family-head;
  font-size: 24px;
  line-height: 115%;
  font-weight: 500;
  padding: 16px 23px 22px;
}
%head-icon{
  position: absolute;
  right: 21px;
  width: 36px;
  height: 36px;
}

.white {
  background: #fff;
}
.border {
  //border: 1px solid $color-border;
  @include border;
}
.round {
  border-radius: 3px;
}
.primary{
  color: #fff!important;
  background: linear-gradient(90deg, #FF5308 0%, #F30874 50%, #C20059 100%);
  background-size: 200%;
  &:hover{
    background-position: 100% 0;
  }
}
[data-hide]:before {
  content: attr(data-hide);
  padding: 0 5px 0 0;
}
/* Для Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/* Для Firefox */
input[type=number]{
  -moz-appearance: textfield;
}
/* Значек Рубля — c нестандартными шрифтами необязателен */
/*i.ruble{
  &:before{
    content: "Р";
    font-style: normal;
    width: 0.5em;
  }
  &:after{
    content: "−";
    position: relative;
    left: -.8em;
    top: .13em;
    font-size: .9em;
    width: .5em;
  }
}*/
/* Tooltip */
.tooltip {
  position: fixed;
  display:none;
  max-width: 250px;
  padding: 5px 12px!important;
  color: $color-active!important;
  line-height: initial!important;
  background: #fff;
  //left: 0;
  border-radius: 3px;
  z-index: 1000;
  @extend %box-shadow;
}
.select-wrap{
  display: inline-block;
  position: relative;
  select{
    font-size: 14px;
    line-height: 28px;
    height: 28px;
    color: $color-active;
    padding: 0 20px 0 8px;
    background: $color-hover-back;
    border-radius: 3px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all .15s;
    &::-ms-expand {
      display: none;
    }
    &.active + i{
      transform: scaleY(-1);
    }
    &:hover{
      //color: $color-hover;
    }
  }
  i{
    position: absolute;
    right: 2px;
    top: 0;
    font-size: 20px;
    line-height: 26px;
    pointer-events: none;
    transition: all .15s /*.1s*/;
  }
}
table{
  border-spacing: 0;
  border-collapse: collapse;
  color: $color-primary;
}
td{
  padding: 5px 4px 4px;
  text-align: center;
  border: 1px solid $color-active-filter;
  &.xf{
    font-size: 18px;
    color: $color-hover;
  }
}
.metal{
  &:before{
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 10px;
    vertical-align: sub;
    margin: 0 5px 0 -1px;
  }
  &.zoloto:before{
    background: linear-gradient(35deg, #E5A300 15%, #F3C762 58%, #EFB30C 87%);
  }
  &.serebro:before{
    background: linear-gradient(35deg, #B5B5B5 15%, #D2D2D2 58%, #C0C0C0 87%);
  }
  &.alyuminiy:before{
    background: linear-gradient(35deg, #c6c6c6 15%, #dfdfdf 58%, #d0d0d0 87%);
  }
  &.platina:before{
    background: linear-gradient(35deg, #c6c6c6 15%, #dfdfdf 58%, #d0d0d0 87%);
  }
  &.latun:before{
    background: linear-gradient(35deg, #C98A40 15%, #EEAF65 58%, #D89950 87%);
  }
  &.bronza:before{
    background: linear-gradient(35deg, #935D45 15%, #C17E60 58%, #A2654D 87%);
  }
  &.tompak:before{
    background: linear-gradient(35deg, #82763d 15%, #c19f60 58%, #927147 87%);
  }
  &.stal:before{
    background: linear-gradient(35deg, #D1D1D1 15%, #B5B5B5 45%, #D1D1D1 65%, #C0C0C0 83%);
  }
  .item-prav &{
    &:before{
      position: absolute;
      left: -6px;
      top: 50%;
      width: 12px;
      height: 12px;
      margin: -6px 0 0;
    }
  }
  .item-znak &{
    &:before{
      position: absolute;
      left: 15px;
      top: 6px;
      width: 12px;
      height: 12px;
      margin: 0;
    }
  }
  .years-wrap &{
    &:before{
      width: 16px;
      height: 16px;
      margin: 0 5px 1px -1px;
    }
  }
}