@media screen and (min-width: 768px) {
  body {
    background-position: 86px 68px;
  }
  .container{
    &.text{
      column-count: 3;
    }
  }
  nav{
    ul.menu{
      margin-right: 245px;
    }
  }
  #wrap-drop{
    right: 55px;
  }
  .button-send{
    width: 234px;
    bottom: auto;
    top: 5px;
    left: auto;
    right: 16px;
    font-size: 16px;
    line-height: 32px;
    border-radius: 3px;
  }
  header{
    .desc{
      display: block;
      width: 227px;
      font-size: 15px;
      padding: 0 8px 0 11px;
      border-left: 1px solid $color-active;
      h4{
        font-size: 15px;
        font-weight: normal;
        color: $color-active;
      }
      p{
        line-height: 125%;
      }
    }
  }
  #contacts{
    margin-left: auto;
    padding: 0 12px;
  }
  .masonry{
    > div{
      width: 33.33%;
    }
  }
  aside.left{
    display: flex;
    justify-content: stretch;
    .search-wrap{
      order: 2;
      width: 66.67%;
      transition: all .2s;
      &.disable{
        width: 62px;
      }
    }
    .filter-wrap{
      flex: 1 1;
      order: 1;
      width: 33.33%;
      transition: all .2s;
    }
  }
  .filter-wrap{
    &.open{
    }
  }
  #filter{
    h4{
      justify-content: space-between;
      i{
        margin: 0 -2px 0 auto;
      }
    }
  }
  #opts{
    > div {
      flex-wrap: nowrap;
    }
    .pravs{
      //padding: 0 16px 24px;
      //border: none;
    }
    .years{
      //border-top: none;
      //padding: 1px 16px 12px;
    }
    .clear{
      top: auto;
      bottom: 2px;
    }
  }
  #filter.filter-articles{
    #opts{
      > div{
        flex-wrap: wrap;
        padding-right: 96px;
      }
    }
  }
  .list-articles{
    .control.top{
      display: flex;
      .pagination{
        display: flex;
        margin-right: auto;
      }
      .onpage {
        margin-left: 8px;
      }
    }
  }

  .product{
    #more{
      display: none;
    }
    .fav-add{
      padding: 0 12px 12px;
    }
    h1{
      float: right;
      width: 66.66%;
      padding: 20px 20px 12px 20px;
    }
    .photos-wrap{
      float: left;
      width: 33.34%;
      padding: 20px 0 24px 16px;
      .photos{
        margin-right: -4px;
      }
    }
    #turn{
      display: none;
    }
    #thumbs{
      display: block;
      padding: 16px;
    }
    .desc{
      float: right;
      width: 66.66%;
      padding-left: 22px;
      &.hidden{
        height: auto;
        overflow: visible;
      }
      &:after{
        display: none;
      }
    }
    table{
      width: 100%;
      tbody{
        display: table;
      }
      tr{
        display: table-row;
      }
      td{
        margin: 0;
      }
    }
    .fav-add {
      position: static;
      float: left;
      padding-left: 18px;
      span{
        display: inline;
        font-size: 14px;
        line-height: 24px;
        vertical-align: bottom;
        margin-left: 5px;
      }
    }
  }
  article{
    .share{
      padding: 12px 42px 16px;
      max-width: 784px;
      margin: 0 auto;
      box-shadow: none;
    }
    h1{
      padding: 20px 41px 8px;
      max-width: 786px;
      margin: 0 auto;
    }
    h2{
      padding: 8px 41px 16px;
      max-width: 786px;
      margin: 0 auto;
    }
    h3{
      padding: 4px 41px 14px;
      max-width: 786px;
      margin: 0 auto;
    }
    p{
      padding: 0 42px 16px;
      max-width: 784px;
      margin: 0 auto;
    }
    > ul{
      max-width: 784px;
      margin: -6px auto 16px;
      padding: 0 58px 0;
    }
    blockquote{
      max-width: 784px;
      margin: 0 auto;
      p{
        padding: 0 80px 16px;
      }
      &:before, &:after{
        font-size: 40px;
      }
      &:before{
        left: 38px;
        top: -4px;
      }
      &:after{
        right: 60px;
        bottom: 6px;
      }
    }
    figcaption {
      padding: 12px 42px 16px;
      text-align: center;
    }
    figure{
      &:first-of-type{
        margin-bottom: 24px;
      }
      &.cols-2, &.cols-3{
        figcaption{
          > div{
            //text-align: center;
          }
        }
      }
    }
    .table-responsive{
      > table{
        max-width: 97%;
        margin: 0 auto 20px;
        &.p{
          max-width: 700px;
        }
      }
    }
    > table{
      max-width: 784px;
      margin: 0 auto 20px;
    }
  }
  .cattext-wrap {
    .cat-photo {
      max-width: 50%;
      float: left;
      margin-right: 16px;
    }
  }


  footer{
    .container{
      display: flex;
      flex-direction: row;
      padding: 40px 8px 45px;
      >div{
        width: 66.67%;
        float: left;
      }
    }
    ul{
      width: 33.33%;
      float: left;
    }
    p{
      margin: 0 20px;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {

  .form-wrap{
    @include form-wrap
  }
  .pluses-wrap{
    @include pluses-wrap
  }
}
@media screen and (min-width: 768px) and (max-width: 1339px) {
  .about-photo-wrap{
    width: 33.33%;
  }
  .about-text-wrap{
    width: 66.67%;
  }
  #form{
    fieldset{
      &.submit{
        margin: 0;
      }
    }
  }
}